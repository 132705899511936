import { helpActions } from "../actions/actionTypes";
import { helpInitState, helpPageInitState } from "../model";

export const helpDetail = (state = helpInitState, action) => {
    switch (action.type) {
        case helpActions.GET_HELP_DETAIL_START:
            return {
                ...helpInitState,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case helpActions.GET_HELP_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: "PERMISSION DENIED",
            };
        case helpActions.GET_HELP_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: true,
                data: action.data,
            };
        case helpActions.GET_HELP_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const helpPage = (state = helpPageInitState, action) => {
    switch (action.type) {
        case helpActions.GET_HELP_PAGE_START:
            return {
                ...helpPageInitState,
                isLoading: true,
                error: action.error,
            };
        case helpActions.GET_HELP_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case helpActions.GET_HELP_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case helpActions.GET_HELP_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};