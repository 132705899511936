import { merchantActions } from "../actions/actionTypes";
import { merchantInitState, merchantUserInitState, merchantUserPageInitState, merchantUserGroupInitState, merchantUserGroupPageInitState, merchantGroupInitState, merchantGroupPageInitState, merchantPageInitState, merchantAccountInitState, merchantAccountPageInitState, merchantLogPageInitState, merchantMessageInitState, merchantMessagePageInitState, merchantLicenseInitState, merchantLicensePageInitState, merchantFileInitState, merchantFilePageInitState, agentRuleInitState, agentRulePageInitState } from "../model";

export const merchantDetail = (state = merchantInitState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const merchantAll = (state = merchantPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_ALL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_ALL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_ALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_ALL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantAction = (state = merchantInitState, action) => {
    switch (action.type) {
        case merchantActions.UPDATE_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_LOGO_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_LOGO_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_LOGO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_LOGO_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_SEAL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_SEAL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_SEAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_SEAL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_AVATAR_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_AVATAR_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_AVATAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_AVATAR_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_BANNER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_BANNER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_BANNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_BANNER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.SWITCH_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.SWITCH_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.SWITCH_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.SWITCH_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const currentMerchantUser = (state = merchantUserInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_CURRENT_MERCHANT_USER_START:
            return {
                ...state,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case merchantActions.GET_CURRENT_MERCHANT_USER_BREAK:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_CURRENT_MERCHANT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: true,
                data: action.data,
            };
        case merchantActions.GET_CURRENT_MERCHANT_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserDetail = (state = merchantUserInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_USER_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_USER_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_USER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_USER_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserPage = (state = merchantUserPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_USER_PAGE_START:
            return {
                ...merchantUserPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_USER_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_USER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_USER_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserAction = (state = merchantUserInitState, action) => {
    switch (action.type) {
        case merchantActions.MERCHANT_USER_LOGIN_START:
            return {
                ...state,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case merchantActions.MERCHANT_USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: true,
                data: action.data,
            };
        case merchantActions.MERCHANT_USER_LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        case merchantActions.MERCHANT_USER_LOGOUT_START:
            return {
                ...state,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case merchantActions.MERCHANT_USER_LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                data: action.data,
            };
        case merchantActions.MERCHANT_USER_LOGOUT_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PHOTO_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PHOTO_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_USER_PHOTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PHOTO_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PROFILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PROFILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_USER_PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.CHANGE_MERCHANT_USER_PASSWORD_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CHANGE_MERCHANT_USER_PASSWORD_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CHANGE_MERCHANT_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CHANGE_MERCHANT_USER_PASSWORD_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.CREATE_MERCHANT_USER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_USER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_USER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_USER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_USER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_USER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserGroupDetail = (state = merchantUserGroupInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserGroupPage = (state = merchantUserGroupPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_USER_GROUP_PAGE_START:
            return {
                ...merchantUserGroupPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_USER_GROUP_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantUserGroupAction = (state = merchantUserGroupInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_USER_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_USER_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_USER_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_USER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_USER_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_USER_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_USER_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_USER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_USER_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_USER_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_USER_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_USER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantGroupDetail = (state = merchantUserGroupInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_GROUP_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_GROUP_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_GROUP_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_GROUP_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantGroupPage = (state = merchantGroupPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_GROUP_PAGE_START:
            return {
                ...merchantGroupPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_GROUP_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_GROUP_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_GROUP_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantGroupAction = (state = merchantGroupInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_GROUP_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentMerchantDetail = (state = merchantInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_MERCHANT_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_AGENT_MERCHANT_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_MERCHANT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_AGENT_MERCHANT_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentMerchantPage = (state = merchantPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_MERCHANT_PAGE_START:
            return {
                ...merchantPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_AGENT_MERCHANT_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_MERCHANT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_AGENT_MERCHANT_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentMerchantAction = (state = merchantInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_AGENT_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_AGENT_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_AGENT_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_AGENT_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_AGENT_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_AGENT_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_AGENT_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_AGENT_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.TERMINATE_AGENT_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.TERMINATE_AGENT_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.TERMINATE_AGENT_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.TERMINATE_AGENT_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.SEND_AUTH_AGENT_MERCHANT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.SEND_AUTH_AGENT_MERCHANT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.SEND_AUTH_AGENT_MERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.SEND_AUTH_AGENT_MERCHANT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantAccountDetail = (state = merchantAccountInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantAccountPage = (state = merchantAccountPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_ACCOUNT_PAGE_START:
            return {
                ...merchantAccountPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_ACCOUNT_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantAccountAction = (state = merchantAccountInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_ACCOUNT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_ACCOUNT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_ACCOUNT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_ACCOUNT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_ACCOUNT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_ACCOUNT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_ACCOUNT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_ACCOUNT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_ACCOUNT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantLogPage = (state = merchantLogPageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_LOG_PAGE_START:
            return {
                ...merchantLogPageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_LOG_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_LOG_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_LOG_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantMessageDetail = (state = merchantMessageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_MESSAGE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_MESSAGE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_MESSAGE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_MESSAGE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantMessagePage = (state = merchantMessagePageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_MESSAGE_PAGE_START:
            return {
                ...merchantMessagePageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_MESSAGE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_MESSAGE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_MESSAGE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantMessageAction = (state = merchantMessageInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_MESSAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_MESSAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_MESSAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_MESSAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_MESSAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_MESSAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_MESSAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_MESSAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_MESSAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantLicenseDetail = (state = merchantLicenseInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_LICENSE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_LICENSE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_LICENSE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_LICENSE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantLicensePage = (state = merchantLicensePageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_LICENSE_PAGE_START:
            return {
                ...merchantLicensePageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_LICENSE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_LICENSE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_LICENSE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantLicenseAction = (state = merchantLicenseInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_LICENSE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_LICENSE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_LICENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_LICENSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_LICENSE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_LICENSE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_LICENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_LICENSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_LICENSE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_LICENSE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_LICENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_LICENSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPLOAD_MERCHANT_LICENSE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPLOAD_MERCHANT_LICENSE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPLOAD_MERCHANT_LICENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPLOAD_MERCHANT_LICENSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantFileDetail = (state = merchantFileInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_FILE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_MERCHANT_FILE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_FILE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_MERCHANT_FILE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantFilePage = (state = merchantFilePageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_MERCHANT_FILE_PAGE_START:
            return {
                ...merchantFilePageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_MERCHANT_FILE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_MERCHANT_FILE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_MERCHANT_FILE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const merchantFileAction = (state = merchantFileInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_MERCHANT_FILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_MERCHANT_FILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_MERCHANT_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_MERCHANT_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_MERCHANT_FILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_MERCHANT_FILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_MERCHANT_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_MERCHANT_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_MERCHANT_FILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_MERCHANT_FILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_MERCHANT_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_MERCHANT_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPLOAD_MERCHANT_FILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPLOAD_MERCHANT_FILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPLOAD_MERCHANT_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPLOAD_MERCHANT_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DOWNLOAD_MERCHANT_FILE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DOWNLOAD_MERCHANT_FILE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DOWNLOAD_MERCHANT_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DOWNLOAD_MERCHANT_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentProductRuleDetail = (state = agentRuleInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentProductRulePage = (state = agentRulePageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_START:
            return {
                ...agentRulePageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentProductRuleAction = (state = agentRuleInitState, action) => {
    switch (action.type) {
        case merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.CREATE_AGENT_PRODUCT_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_AGENT_PRODUCT_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_AGENT_PRODUCT_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_AGENT_PRODUCT_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_AGENT_PRODUCT_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_AGENT_PRODUCT_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_AGENT_PRODUCT_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_AGENT_PRODUCT_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_AGENT_PRODUCT_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_AGENT_PRODUCT_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_AGENT_PRODUCT_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_AGENT_PRODUCT_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentAddonRuleDetail = (state = agentRuleInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_ADDON_RULE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case merchantActions.GET_AGENT_ADDON_RULE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_ADDON_RULE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.GET_AGENT_ADDON_RULE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentAddonRulePage = (state = agentRulePageInitState, action) => {
    switch (action.type) {
        case merchantActions.GET_AGENT_ADDON_RULE_PAGE_START:
            return {
                ...agentRulePageInitState,
                isLoading: true,
                error: action.error,
            };
        case merchantActions.GET_AGENT_ADDON_RULE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.GET_AGENT_ADDON_RULE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case merchantActions.GET_AGENT_ADDON_RULE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const agentAddonRuleAction = (state = agentRuleInitState, action) => {
    switch (action.type) {
        case merchantActions.CREATE_AGENT_ADDON_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.CREATE_AGENT_ADDON_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.CREATE_AGENT_ADDON_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.CREATE_AGENT_ADDON_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.UPDATE_AGENT_ADDON_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.UPDATE_AGENT_ADDON_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.UPDATE_AGENT_ADDON_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.UPDATE_AGENT_ADDON_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case merchantActions.DELETE_AGENT_ADDON_RULE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case merchantActions.DELETE_AGENT_ADDON_RULE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case merchantActions.DELETE_AGENT_ADDON_RULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case merchantActions.DELETE_AGENT_ADDON_RULE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
