export const merchantInitState = {
    data: {
        merchantId: 0,
        merchantName: "罗缤电商工坊",
        merchantResell: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantUserInitState = {
    data: {
        userId: 0,
        userEmail: "",
        realName: "",
        userPhone: "",
        userGender: 0,
        userBirthDate: "",
    },
    isLoading: false,
    isLogin: false,
    isSuccess: false,
    error: "",
};

export const merchantUserPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantUserGroupInitState = {
    data: {
        groupId: 0,
        groupName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantUserGroupPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantGroupInitState = {
    data: {
        groupId: 0,
        groupName: "",
        groupExcludeProducts: [],
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantGroupPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantAccountInitState = {
    data: {
        accountId: 0,
        accountName: "",
        accountDefault: false,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantAccountPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantMessageInitState = {
    data: {
        messageId: 0,
        messageSubject: "",
        messageContent: "",
        messageSendEmail: false,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantMessagePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantLicenseInitState = {
    data: {
        licenseId: 0,
        licenseName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantLicensePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantFileInitState = {
    data: {
        fileId: 0,
        fileName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const merchantFilePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const merchantLogPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const agentRuleInitState = {
    data: {
        ruleId: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const agentRulePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const staticInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
};

export const channelInitState = {
    data: {
        channelId: 0,
        channelName: "",
        channelLogo: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const channelPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const channelPromotionInitState = {
    data: {
        promotionId: 0,
        promotionName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const channelPromotionPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const channelPromotionProductInitState = {
    data: {
        promotionId: 0,
        productId: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const channelPromotionProductPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const hotelInitState = {
    data: {
        hotelId: 0,
        hotelName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const hotelPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const bandInitState = {
    data: {
        bandId: 0,
        bandName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const bandPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const addonInitState = {
    data: {
        addonId: 0,
        addonName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const addonPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const cancellationInitState = {
    data: {
        cancellationId: 0,
        cancellationName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const cancellationPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const locationInitState = {
    data: {
        locationId: 0,
        locationName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const locationPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const roomtypeInitState = {
    data: {
        roomtypeId: 0,
        roomtypeName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const roomtypePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const tagInitState = {
    data: {
        tagId: 0,
        tagName: "",
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const tagPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const productInitState = {
    data: {
        productId: 0,
        productName: "",
        productContent: "",
        productPhotos: [],
        productOptions: [],
        productBands: [],
        productParameters: [],
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const productPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const productInventoryMonthState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const productInventoryCalendarState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const productInventoryDateState = {
    data: {},
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const productLanguageInitState = {
    data: {
        languageId: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const productLanguageAllInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
};

export const questionInitState = {
    data: {
        questionId: 0,
        userId: 0,
        supplierId: 0,
        productId: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const questionPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const helpInitState = {
    data: {
        helpId: 0,
    },
    isLoading: false,
    isSuccess: false,
    error: "",
};

export const helpPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const orderInitState = {
    data: {
        orderId: 0,
        merchantId: 0,
        agentMerchantId: 0,
        bookings: [],
    },
    isLoading: false,
    isSuccess: false,
};

export const orderDetailPageInitState = {
    data: {
        order: {
            orderId: 0,
            merchantId: 0,
            agentMerchantId: 0,
            bookings: [],
        },
        page: "",
    },
    isLoading: false,
    isSuccess: false,
};

export const orderPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const orderFeeInitState = {
    data: {
        feeId: 0,
        orderId: 0,
    },
    isLoading: false,
    isSuccess: false,
};

export const orderFeeDetailPageInitState = {
    data: {
        fee: {
            feeId: 0,
            orderId: 0,
        },
        page: "",
    },
    isLoading: false,
    isSuccess: false,
};

export const orderLogInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
};

export const financeInitState = {
    data: {},
    isLoading: false,
    isSuccess: false,
};

export const invoiceInitState = {
    data: {
        invoiceId: 0,
    },
    isLoading: false,
    isSuccess: false,
};

export const invoicePageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};

export const billInitState = {
    data: {
        billId: 0,
    },
    isLoading: false,
    isSuccess: false,
};

export const billPageInitState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalRecords: 0,
    currentPage: 1,
    totalPages: 1,
};
