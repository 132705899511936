import { combineReducers } from "redux";
import * as Merchant from "./merchant";
import * as Order from "./order";
import * as Product from "./product";
import * as Information from "./information";
import * as Resource from "./resource";
import * as Question from "./question";
import * as Supplier from "./supplier";
import * as Finance from "./finance";
import * as Channel from "./channel";
import * as Static from "./static";
import * as Help from "./help";

export default combineReducers({
    ...Merchant,
    ...Order,
    ...Product,
    ...Information,
    ...Resource,
    ...Question,
    ...Supplier,
    ...Finance,
    ...Channel,
    ...Static,
    ...Help,
});
