import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AppRouter from "./utilities/router";
import "antd/dist/antd.css";
import "./assets/css/main.scss";
import { Config } from "./utilities/config";
import "./utilities/i18n";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.clearCache();
    }
    clearCache() {
        let appVersion = localStorage.getItem("appVersion");
        if (appVersion !== Config.Version) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
            localStorage.setItem("appVersion", Config.Version);
        }
    }
    render() {
        return (
            <Provider store={store}>
                <AppRouter></AppRouter>
            </Provider>
        );
    }
}

export default App;
