import { channelActions } from "../actions/actionTypes";
import { channelInitState, channelPageInitState, channelPromotionInitState, channelPromotionPageInitState, channelPromotionProductInitState, channelPromotionProductPageInitState } from "../model";

export const channelDetail = (state = channelInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case channelActions.GET_CHANNEL_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case channelActions.GET_CHANNEL_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPage = (state = channelPageInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_PAGE_START:
            return {
                ...channelPageInitState,
                isLoading: true,
                error: action.error,
            };
        case channelActions.GET_CHANNEL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case channelActions.GET_CHANNEL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionDetail = (state = channelInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_PROMOTION_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case channelActions.GET_CHANNEL_PROMOTION_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_PROMOTION_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case channelActions.GET_CHANNEL_PROMOTION_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionPage = (state = channelPromotionPageInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_PROMOTION_PAGE_START:
            return {
                ...channelPromotionPageInitState,
                isLoading: true,
                error: action.error,
            };
        case channelActions.GET_CHANNEL_PROMOTION_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_PROMOTION_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case channelActions.GET_CHANNEL_PROMOTION_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionAction = (state = channelPromotionInitState, action) => {
    switch (action.type) {
        case channelActions.JOIN_CHANNEL_PROMOTION_START:
            return {
                ...channelPromotionInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case channelActions.JOIN_CHANNEL_PROMOTION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.JOIN_CHANNEL_PROMOTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case channelActions.JOIN_CHANNEL_PROMOTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionProductDetail = (state = channelPromotionProductInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionProductPage = (state = channelPromotionProductPageInitState, action) => {
    switch (action.type) {
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_PAGE_START:
            return {
                ...channelPromotionProductPageInitState,
                isLoading: true,
                error: action.error,
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case channelActions.GET_CHANNEL_PROMOTION_PRODUCT_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelPromotionProductAction = (state = channelPromotionProductInitState, action) => {
    switch (action.type) {
        case channelActions.DELETE_CHANNEL_PROMOTION_PRODUCT_START:
            return {
                ...channelPromotionProductInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case channelActions.DELETE_CHANNEL_PROMOTION_PRODUCT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case channelActions.DELETE_CHANNEL_PROMOTION_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case channelActions.DELETE_CHANNEL_PROMOTION_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
