import { productActions } from "../actions/actionTypes";
import { productInitState, productPageInitState, productInventoryDateState, productInventoryMonthState, productInventoryCalendarState, productLanguageInitState, productLanguageAllInitState, productQuestionInitState, productQuestionPageInitState } from "../model";

export const productDetail = (state = productInitState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_DETAIL_START:
            return {
                ...productInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case productActions.GET_PRODUCT_DETAIL_BREAK:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.GET_PRODUCT_DETAIL_FAILED:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productQrcode = (state = productInitState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_QRCODE_START:
            return {
                ...productInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case productActions.GET_PRODUCT_QRCODE_BREAK:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_QRCODE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.GET_PRODUCT_QRCODE_FAILED:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productPage = (state = productPageInitState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_PAGE_START:
            return {
                ...productPageInitState,
                isLoading: true,
                error: action.error,
            };
        case productActions.GET_PRODUCT_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case productActions.GET_PRODUCT_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productInventoryMonth = (state = productInventoryMonthState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_MONTH_INVENTORY_START:
            return {
                ...productInventoryMonthState,
                isLoading: true,
                error: action.error,
            };
        case productActions.GET_PRODUCT_MONTH_INVENTORY_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_MONTH_INVENTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case productActions.GET_PRODUCT_MONTH_INVENTORY_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productInventoryCalendar = (state = productInventoryCalendarState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_INVENTORY_CALENDAR_START:
            return {
                ...productInventoryCalendarState,
                isLoading: true,
                error: action.error,
            };
        case productActions.GET_PRODUCT_INVENTORY_CALENDAR_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_INVENTORY_CALENDAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case productActions.GET_PRODUCT_INVENTORY_CALENDAR_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productAction = (state = productInitState, action) => {
    switch (action.type) {
        case productActions.CREATE_PRODUCT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.CREATE_PRODUCT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.CREATE_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_ITINERARY_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_ITINERARY_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_ITINERARY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_ITINERARY_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_COMBINE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_COMBINE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_COMBINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_COMBINE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_INVENTORY_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_INVENTORY_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_INVENTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_INVENTORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.SYNC_PRODUCT_INVENTORY_BY_CONDITION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.SYNC_PRODUCT_INVENTORY_BY_CONDITION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.SYNC_PRODUCT_INVENTORY_BY_CONDITION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.SYNC_PRODUCT_INVENTORY_BY_CONDITION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.DELETE_PRODUCT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.DELETE_PRODUCT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.DELETE_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.DUPLICATE_PRODUCT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.DUPLICATE_PRODUCT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.DUPLICATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.DUPLICATE_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productLanguageDetail = (state = productLanguageInitState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_LANGUAGE_DETAIL_START:
            return {
                ...productLanguageInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case productActions.GET_PRODUCT_LANGUAGE_DETAIL_BREAK:
            return {
                ...productLanguageInitState,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_LANGUAGE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.GET_PRODUCT_LANGUAGE_DETAIL_FAILED:
            return {
                ...productLanguageInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const productLanguageAll = (state = productLanguageAllInitState, action) => {
    switch (action.type) {
        case productActions.GET_PRODUCT_LANGUAGE_ALL_START:
            return {
                ...productLanguageAllInitState,
                isLoading: true,
                error: action.error,
            };
        case productActions.GET_PRODUCT_LANGUAGE_ALL_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case productActions.GET_PRODUCT_LANGUAGE_ALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case productActions.GET_PRODUCT_LANGUAGE_ALL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return productLanguageAllInitState;
    }
};

export const productLanguageAction = (state = productLanguageInitState, action) => {
    switch (action.type) {
        case productActions.CREATE_PRODUCT_LANGUAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.CREATE_PRODUCT_LANGUAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.CREATE_PRODUCT_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.CREATE_PRODUCT_LANGUAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.UPDATE_PRODUCT_LANGUAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.UPDATE_PRODUCT_LANGUAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.UPDATE_PRODUCT_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.UPDATE_PRODUCT_LANGUAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case productActions.DELETE_PRODUCT_LANGUAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case productActions.DELETE_PRODUCT_LANGUAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case productActions.DELETE_PRODUCT_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case productActions.DELETE_PRODUCT_LANGUAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
