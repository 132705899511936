import { informationActions } from "../actions/actionTypes";
import { bandInitState, bandPageInitState, addonInitState, addonPageInitState, cancellationInitState, cancellationPageInitState, locationInitState, locationPageInitState, roomtypeInitState, roomtypePageInitState, tagInitState, tagPageInitState } from "../model";

export const bandDetail = (state = bandInitState, action) => {
    switch (action.type) {
        case informationActions.GET_BAND_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_BAND_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_BAND_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_BAND_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const bandPage = (state = bandPageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_BAND_PAGE_START:
            return {
                ...bandPageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_BAND_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_BAND_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_BAND_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const bandAction = (state = bandInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_BAND_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_BAND_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_BAND_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_BAND_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_BAND_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_BAND_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_BAND_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_BAND_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_BAND_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_BAND_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_BAND_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_BAND_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const addonDetail = (state = addonInitState, action) => {
    switch (action.type) {
        case informationActions.GET_ADDON_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_ADDON_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_ADDON_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_ADDON_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const addonPage = (state = addonPageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_ADDON_PAGE_START:
            return {
                ...addonPageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_ADDON_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_ADDON_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_ADDON_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const addonAction = (state = addonInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_ADDON_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_ADDON_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_ADDON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_ADDON_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_ADDON_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_ADDON_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_ADDON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_ADDON_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_ADDON_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_ADDON_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_ADDON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_ADDON_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const cancellationDetail = (state = cancellationInitState, action) => {
    switch (action.type) {
        case informationActions.GET_CANCELLATION_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_CANCELLATION_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_CANCELLATION_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_CANCELLATION_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const cancellationPage = (state = cancellationPageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_CANCELLATION_PAGE_START:
            return {
                ...cancellationPageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_CANCELLATION_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_CANCELLATION_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_CANCELLATION_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const cancellationAction = (state = cancellationInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_CANCELLATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_CANCELLATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_CANCELLATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_CANCELLATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_CANCELLATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_CANCELLATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_CANCELLATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_CANCELLATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_CANCELLATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_CANCELLATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_CANCELLATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_CANCELLATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const locationDetail = (state = locationInitState, action) => {
    switch (action.type) {
        case informationActions.GET_LOCATION_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_LOCATION_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_LOCATION_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_LOCATION_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const locationPage = (state = locationPageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_LOCATION_PAGE_START:
            return {
                ...locationPageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_LOCATION_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_LOCATION_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_LOCATION_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const locationAction = (state = locationInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_LOCATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_LOCATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_LOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_LOCATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_LOCATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_LOCATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_LOCATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_LOCATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_LOCATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_LOCATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const roomtypeDetail = (state = roomtypeInitState, action) => {
    switch (action.type) {
        case informationActions.GET_ROOMTYPE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_ROOMTYPE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_ROOMTYPE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_ROOMTYPE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const roomtypePage = (state = roomtypePageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_ROOMTYPE_PAGE_START:
            return {
                ...roomtypePageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_ROOMTYPE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_ROOMTYPE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_ROOMTYPE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const roomtypeAction = (state = roomtypeInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_ROOMTYPE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_ROOMTYPE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_ROOMTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_ROOMTYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_ROOMTYPE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_ROOMTYPE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_ROOMTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_ROOMTYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_ROOMTYPE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_ROOMTYPE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_ROOMTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_ROOMTYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const tagDetail = (state = tagInitState, action) => {
    switch (action.type) {
        case informationActions.GET_TAG_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case informationActions.GET_TAG_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_TAG_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.GET_TAG_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const tagPage = (state = tagPageInitState, action) => {
    switch (action.type) {
        case informationActions.GET_TAG_PAGE_START:
            return {
                ...tagPageInitState,
                isLoading: true,
                error: action.error,
            };
        case informationActions.GET_TAG_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.GET_TAG_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case informationActions.GET_TAG_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const tagAction = (state = tagInitState, action) => {
    switch (action.type) {
        case informationActions.CREATE_TAG_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.CREATE_TAG_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.CREATE_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.CREATE_TAG_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPDATE_TAG_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPDATE_TAG_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPDATE_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPDATE_TAG_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPLOAD_TAG_IMAGE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPLOAD_TAG_IMAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPLOAD_TAG_IMAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPLOAD_TAG_IMAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.UPLOAD_TAG_ICON_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.UPLOAD_TAG_ICON_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.UPLOAD_TAG_ICON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.UPLOAD_TAG_ICON_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case informationActions.DELETE_TAG_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case informationActions.DELETE_TAG_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case informationActions.DELETE_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case informationActions.DELETE_TAG_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
