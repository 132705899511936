import React from 'react';
import { Skeleton } from "antd";

class PageLoading extends React.Component {
    render() {
        return (
            <Skeleton active></Skeleton>
        );
    }
}

export default PageLoading;