import { supplierActions } from "../actions/actionTypes";
import { merchantInitState, merchantPageInitState, productInitState, productPageInitState, productInventoryMonthState, orderInitState, orderPageInitState, orderDetailPageInitState, orderFeeInitState, orderFeeDetailPageInitState, merchantMessageInitState, merchantMessagePageInitState } from "../model";

export const supplierDetail = (state = merchantInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_SUPPLIER_DETAIL_START:
            return {
                ...merchantInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case supplierActions.GET_SUPPLIER_DETAIL_BREAK:
            return {
                ...merchantInitState,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_SUPPLIER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.GET_SUPPLIER_DETAIL_FAILED:
            return {
                ...merchantInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierPage = (state = merchantPageInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_SUPPLIER_PAGE_START:
            return {
                ...merchantPageInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_SUPPLIER_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_SUPPLIER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case supplierActions.GET_SUPPLIER_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierAction = (state = merchantInitState, action) => {
    switch (action.type) {
        case supplierActions.UPDATE_SUPPLIER_START:
            return {
                ...merchantInitState,
                isLoading: true,
                isSuccess: false,
            };
        case supplierActions.UPDATE_SUPPLIER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.UPDATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.UPDATE_SUPPLIER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierProductDetail = (state = productInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_PRODUCT_DETAIL_START:
            return {
                ...productInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case supplierActions.GET_PRODUCT_DETAIL_BREAK:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.GET_PRODUCT_DETAIL_FAILED:
            return {
                ...productInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierProductInventoryMonth = (state = productInventoryMonthState, action) => {
    switch (action.type) {
        case supplierActions.GET_PRODUCT_MONTH_INVENTORY_START:
            return {
                ...productInventoryMonthState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_PRODUCT_MONTH_INVENTORY_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_PRODUCT_MONTH_INVENTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case supplierActions.GET_PRODUCT_MONTH_INVENTORY_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierProductPage = (state = productPageInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_PRODUCT_PAGE_START:
            return {
                ...productPageInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_PRODUCT_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_PRODUCT_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case supplierActions.GET_PRODUCT_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierOrderDetail = (state = orderInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_ORDER_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case supplierActions.GET_ORDER_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.GET_ORDER_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case supplierActions.GET_ORDER_DETAIL_PAGE_START:
            return {
                ...orderDetailPageInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case supplierActions.GET_ORDER_DETAIL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_ORDER_DETAIL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.GET_ORDER_DETAIL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierOrderPage = (state = orderPageInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_ORDER_PAGE_START:
            return {
                ...orderPageInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_ORDER_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_ORDER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case supplierActions.GET_ORDER_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierOrderAction = (state = orderInitState, action) => {
    switch (action.type) {
        case supplierActions.CREATE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case supplierActions.CREATE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.CREATE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case supplierActions.UPDATE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case supplierActions.UPDATE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.UPDATE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case supplierActions.CANCEL_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case supplierActions.CANCEL_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.CANCEL_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case supplierActions.CHECK_ORDER_SEAT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case supplierActions.CHECK_ORDER_SEAT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.CHECK_ORDER_SEAT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.CHECK_ORDER_SEAT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderFeeDetail = (state = orderFeeInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_ORDER_FEE_DETAIL_START:
            return {
                ...orderFeeInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_PAGE_START:
            return {
                ...orderFeeDetailPageInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case supplierActions.GET_ORDER_FEE_DETAIL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierMessageDetail = (state = merchantMessageInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_MESSAGE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case supplierActions.GET_MESSAGE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_MESSAGE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case supplierActions.GET_MESSAGE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const supplierMessagePage = (state = merchantMessagePageInitState, action) => {
    switch (action.type) {
        case supplierActions.GET_MESSAGE_PAGE_START:
            return {
                ...merchantMessagePageInitState,
                isLoading: true,
                error: action.error,
            };
        case supplierActions.GET_MESSAGE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case supplierActions.GET_MESSAGE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case supplierActions.GET_MESSAGE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
