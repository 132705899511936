import React, { lazy, Suspense } from "react";
import history from "./history";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentMerchantUser } from "../redux/actions/merchant";
import PageLoading from "../components/common/PageLoading";
import { withTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import zh_TW from "antd/lib/locale/zh_TW";
import en_US from "antd/lib/locale/en_US";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import "moment/locale/en-ca";

// AUTH
const AuthLogin = lazy(() => import("../pages/auth/login"));
const AuthActivate = lazy(() => import("../pages/auth/activate"));

// INDEX
const IndexIndex = lazy(() => import("../pages/index/index"));
const UserResult = lazy(() => import("../pages/user/result"));
// MY
const MyProfile = lazy(() => import("../pages/my/profile"));
const MyPassword = lazy(() => import("../pages/my/password"));
// ORDER
const OrderAdd = lazy(() => import("../pages/order/add"));
const OrderDetail = lazy(() => import("../pages/order/detail"));
const OrderReceive = lazy(() => import("../pages/order/receive"));
const OrderConfirm = lazy(() => import("../pages/order/confirm"));
const OrderCancel = lazy(() => import("../pages/order/cancel"));
const OrderComplete = lazy(() => import("../pages/order/complete"));
const OrderEditLocation = lazy(() => import("../pages/order/edit_location"));
const OrderEditConfirmNote = lazy(() => import("../pages/order/edit_confirm_note"));
const OrderResult = lazy(() => import("../pages/order/result"));
const OrderFeeAdd = lazy(() => import("../pages/order/fee_add"));
const OrderFeeDetail = lazy(() => import("../pages/order/fee_detail"));
// PRODUCT
const ProductResult = lazy(() => import("../pages/product/result"));
const ProductDetail = lazy(() => import("../pages/product/detail"));
const ProductAdd = lazy(() => import("../pages/product/add"));
const ProductEdit = lazy(() => import("../pages/product/edit"));
const ProductDetailEdit = lazy(() => import("../pages/product/detail_edit"));
const ProductItineraryEdit = lazy(() => import("../pages/product/itinerary_edit"));
const ProductCombineEdit = lazy(() => import("../pages/product/combine_edit"));
const ProductInventoryEdit = lazy(() => import("../pages/product/inventory_edit"));
const ProductLanguageResult = lazy(() => import("../pages/product/language_result"));
const ProductLanguageAdd = lazy(() => import("../pages/product/language_add"));
const ProductLanguageEdit = lazy(() => import("../pages/product/language_edit"));
const ProductDuplicate = lazy(() => import("../pages/product/duplicate"));
const ProductInventory = lazy(() => import("../pages/product/inventory"));
// AGENT
const AgentGroupResult = lazy(() => import("../pages/agent/group_result"));
const AgentGroupAdd = lazy(() => import("../pages/agent/group_add"));
const AgentGroupEdit = lazy(() => import("../pages/agent/group_edit"));
const AgentResult = lazy(() => import("../pages/agent/result"));
const AgentAdd = lazy(() => import("../pages/agent/add"));
const AgentEdit = lazy(() => import("../pages/agent/edit"));
const AgentMessageResult = lazy(() => import("../pages/agent/message_result"));
const AgentMessageAdd = lazy(() => import("../pages/agent/message_add"));
const AgentMessageEdit = lazy(() => import("../pages/agent/message_edit"));
const AgentMessageDetail = lazy(() => import("../pages/agent/message_detail"));
const AgentProductRuleResult = lazy(() => import("../pages/agent/product_rule_result"));
const AgentProductRuleBatchAdd = lazy(() => import("../pages/agent/product_rule_batch_add"));
const AgentProductRuleAdd = lazy(() => import("../pages/agent/product_rule_add"));
const AgentProductRuleEdit = lazy(() => import("../pages/agent/product_rule_edit"));
const AgentAddonRuleResult = lazy(() => import("../pages/agent/addon_rule_result"));
const AgentAddonRuleAdd = lazy(() => import("../pages/agent/addon_rule_add"));
const AgentAddonRuleEdit = lazy(() => import("../pages/agent/addon_rule_edit"));
const AgentPromotionResult = lazy(() => import("../pages/agent/promotion_result"));
const AgentPromotionDetail = lazy(() => import("../pages/agent/promotion_detail"));
// SUPPLIER
const SupplierResult = lazy(() => import("../pages/supplier/result"));
const SupplierEdit = lazy(() => import("../pages/supplier/edit"));
const SupplierProductResult = lazy(() => import("../pages/supplier/product_result"));
const SupplierProductDetail = lazy(() => import("../pages/supplier/product_detail"));
const SupplierMessageResult = lazy(() => import("../pages/supplier/message_result"));
const SupplierMessageDetail = lazy(() => import("../pages/supplier/message_detail"));
const SupplierOrderAdd = lazy(() => import("../pages/supplier/order_add"));
const SupplierOrderEdit = lazy(() => import("../pages/supplier/order_edit"));
const SupplierOrderCancel = lazy(() => import("../pages/supplier/order_cancel"));
const SupplierOrderResult = lazy(() => import("../pages/supplier/order_result"));
const SupplierOrderDetail = lazy(() => import("../pages/supplier/order_detail"));
const SupplierOrderFeeDetail = lazy(() => import("../pages/supplier/order_fee_detail"));
// FINANCE
const FinanceCurrency = lazy(() => import("../pages/finance/currency"));
const FinancePayment = lazy(() => import("../pages/finance/payment"));
const FinanceFileResult = lazy(() => import("../pages/finance/file_result"));
const FinanceChannelAccountResult = lazy(() => import("../pages/finance/channel_account_result"));
const FinanceAccountResult = lazy(() => import("../pages/finance/account_result"));
const FinanceAccountAdd = lazy(() => import("../pages/finance/account_add"));
const FinanceAccountEdit = lazy(() => import("../pages/finance/account_edit"));
const FinanceReceivableResult = lazy(() => import("../pages/finance/receivable_result"));
const FinanceBillReceivableResult = lazy(() => import("../pages/finance/bill_receivable_result"));
const FinancePayableResult = lazy(() => import("../pages/finance/payable_result"));
const FinanceBillPayableResult = lazy(() => import("../pages/finance/bill_payable_result"));
const FinanceBillConfirm = lazy(() => import("../pages/finance/bill_confirm"));
// INFORMATION
const InformationTagResult = lazy(() => import("../pages/information/tag_result"));
const InformationTagAdd = lazy(() => import("../pages/information/tag_add"));
const InformationTagEdit = lazy(() => import("../pages/information/tag_edit"));
const InformationBandResult = lazy(() => import("../pages/information/band_result"));
const InformationBandAdd = lazy(() => import("../pages/information/band_add"));
const InformationBandEdit = lazy(() => import("../pages/information/band_edit"));
const InformationAddonResult = lazy(() => import("../pages/information/addon_result"));
const InformationAddonAdd = lazy(() => import("../pages/information/addon_add"));
const InformationAddonEdit = lazy(() => import("../pages/information/addon_edit"));
const InformationCancellationResult = lazy(() => import("../pages/information/cancellation_result"));
const InformationCancellationAdd = lazy(() => import("../pages/information/cancellation_add"));
const InformationCancellationEdit = lazy(() => import("../pages/information/cancellation_edit"));
const InformationLocationResult = lazy(() => import("../pages/information/location_result"));
const InformationLocationAdd = lazy(() => import("../pages/information/location_add"));
const InformationLocationEdit = lazy(() => import("../pages/information/location_edit"));
const InformationRoomtypeResult = lazy(() => import("../pages/information/roomtype_result"));
const InformationRoomtypeAdd = lazy(() => import("../pages/information/roomtype_add"));
const InformationRoomtypeEdit = lazy(() => import("../pages/information/roomtype_edit"));
// RESOURCE
const ResourceHotelResult = lazy(() => import("../pages/resource/hotel_result"));
const ResourceHotelAdd = lazy(() => import("../pages/resource/hotel_add"));
const ResourceHotelEdit = lazy(() => import("../pages/resource/hotel_edit"));
// QUESTION
const QuestionResult = lazy(() => import("../pages/question/result"));
const QuestionReply = lazy(() => import("../pages/question/reply"));
// OPERATE
const OperateCalendar = lazy(() => import("../pages/operate/calendar"));
// SETTING
const SettingCompany = lazy(() => import("../pages/setting/company"));
const SettingLicenseAdd = lazy(() => import("../pages/setting/license_add"));
const SettingLicenseEdit = lazy(() => import("../pages/setting/license_edit"));
const SettingLicenseResult = lazy(() => import("../pages/setting/license_result"));
const SettingGroupAdd = lazy(() => import("../pages/setting/group_add"));
const SettingGroupEdit = lazy(() => import("../pages/setting/group_edit"));
const SettingGroupResult = lazy(() => import("../pages/setting/group_result"));
const SettingUserAdd = lazy(() => import("../pages/setting/user_add"));
const SettingUserEdit = lazy(() => import("../pages/setting/user_edit"));
const SettingUserResult = lazy(() => import("../pages/setting/user_result"));
// HELP
const HelpResult = lazy(() => import("../pages/help/result"));
const HelpDetail = lazy(() => import("../pages/help/detail"));

class AppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userStatusLoaded: false,
        };
    }
    componentDidMount() {
        this.props.getCurrentMerchantUser().then(() => {
            this.setState({
                userStatusLoaded: true,
            });
        });
        let params = new URLSearchParams(history.location.search);
        let channelId = params.get("channelId") || 0;
        if (channelId > 0) {
            localStorage.setItem("fromChannelId", channelId);
        }
    }
    render() {
        let locale = zh_CN;
        if (this.props.i18n.language === "zh-TW") {
            locale = zh_TW;
            moment.locale("zh-tw");
        } else if (this.props.i18n.language === "en-US") {
            locale = en_US;
            moment.locale("en-ca");
        } else {
            moment.locale("zh-cn");
        }
        return (
            <ConfigProvider locale={locale}>
                <BrowserRouter history={history}>
                    <Suspense fallback={<PageLoading></PageLoading>}>
                        {this.state.userStatusLoaded &&
                            !this.props.isLoginUserLoading &&
                            (this.props.isLoginUserLogin ? (
                                <>
                                    <Switch>
                                        <Route path="/" exact component={IndexIndex}></Route>
                                        <Route path="/my/profile" exact component={MyProfile}></Route>
                                        <Route path="/my/password" exact component={MyPassword}></Route>
                                        {/* ORDER */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.add)) && <Route path="/order/add/:productId" exact component={OrderAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.detail)) && <Route path="/order/detail/:orderId" exact component={OrderDetail}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.receive)) && <Route path="/order/receive/:orderId" exact component={OrderReceive}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.confirm)) && <Route path="/order/confirm/:orderId" exact component={OrderConfirm}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.cancel)) && <Route path="/order/cancel/:orderId" exact component={OrderCancel}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.complete)) && <Route path="/order/complete/:orderId" exact component={OrderComplete}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.edit_location)) && <Route path="/order/edit_location/:orderId" exact component={OrderEditLocation}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.edit_confirm_note)) && <Route path="/order/edit_confirm_note/:orderId" exact component={OrderEditConfirmNote}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.result)) && <Route path="/order/result" exact component={OrderResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.fee_add)) && <Route path="/order/fee_add/:orderId" exact component={OrderFeeAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.order && this.props.loginUser.userPermissions.order.fee_detail)) && <Route path="/order/fee_detail/:feeId" exact component={OrderFeeDetail}></Route>}
                                        {/* PRODUCT */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.result)) && <Route path="/product/result" exact component={ProductResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.detail)) && <Route path="/product/detail/:productId" exact component={ProductDetail}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.add)) && <Route path="/product/add" exact component={ProductAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.edit)) && <Route path="/product/edit/:productId" exact component={ProductEdit}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.detail_edit)) && <Route path="/product/detail_edit/:productId" exact component={ProductDetailEdit}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.itinerary_edit)) && <Route path="/product/itinerary_edit/:productId" exact component={ProductItineraryEdit}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.combine_edit)) && <Route path="/product/combine_edit/:productId" exact component={ProductCombineEdit}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.inventory_edit)) && <Route path="/product/inventory_edit/:productId" exact component={ProductInventoryEdit}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.duplicate)) && <Route path="/product/duplicate/:productId" exact component={ProductDuplicate}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.inventory)) && <Route path="/product/inventory" exact component={ProductInventory}></Route>}
                                        {/* PRODUCT.language */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.language_result)) && <Route path="/product/language_result/:productId" exact component={ProductLanguageResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.language_add)) && <Route path="/product/language_add/:productId" exact component={ProductLanguageAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.product && this.props.loginUser.userPermissions.product.language_edit)) && <Route path="/product/language_edit/:productId/:languageId" exact component={ProductLanguageEdit}></Route>}
                                        {/* QUESTION */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.question && this.props.loginUser.userPermissions.question.result)) && <Route path="/question/result" exact component={QuestionResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.question && this.props.loginUser.userPermissions.question.reply)) && <Route path="/question/reply/:questionId" exact component={QuestionReply}></Route>}
                                        {/* OPERATE */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.operate && this.props.loginUser.userPermissions.operate.calendar)) && <Route path="/operate/calendar" exact component={OperateCalendar}></Route>}
                                        {/* AGENT */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.result)) && <Route path="/agent/result" exact component={AgentResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.add)) && <Route path="/agent/add" exact component={AgentAdd}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.edit)) && <Route path="/agent/edit/:agentId" exact component={AgentEdit}></Route>}
                                        {/* AGENT.group */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.group_result)) && <Route path="/agent/group_result" exact component={AgentGroupResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.group_add)) && <Route path="/agent/group_add" exact component={AgentGroupAdd}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.group_edit)) && <Route path="/agent/group_edit/:groupId" exact component={AgentGroupEdit}></Route>}
                                        {/* AGENT.product_rule */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.product_rule_result)) && <Route path="/agent/product_rule_result" exact component={AgentProductRuleResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.product_rule_add)) && <Route path="/agent/product_rule_add" exact component={AgentProductRuleAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.product_rule_batch_add)) && <Route path="/agent/product_rule_batch_add" exact component={AgentProductRuleBatchAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.product_rule_edit)) && <Route path="/agent/product_rule_edit/:ruleId" exact component={AgentProductRuleEdit}></Route>}
                                        {/* AGENT.addon_rule */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.addon_rule_result)) && <Route path="/agent/addon_rule_result" exact component={AgentAddonRuleResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.addon_rule_add)) && <Route path="/agent/addon_rule_add" exact component={AgentAddonRuleAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.addon_rule_edit)) && <Route path="/agent/addon_rule_edit/:ruleId" exact component={AgentAddonRuleEdit}></Route>}
                                        {/* AGENT.message */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.message_result)) && <Route path="/agent/message_result" exact component={AgentMessageResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.message_detail)) && <Route path="/agent/message_detail/:messageId" exact component={AgentMessageDetail}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.message_add)) && <Route path="/agent/message_add" exact component={AgentMessageAdd}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.message_edit)) && <Route path="/agent/message_edit/:messageId" exact component={AgentMessageEdit}></Route>}
                                        {/* AGENT.promotion */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.promotion_result)) && <Route path="/agent/promotion_result" exact component={AgentPromotionResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.agent && this.props.loginUser.userPermissions.agent.promotion_detail)) && <Route path="/agent/promotion_detail/:promotionId" exact component={AgentPromotionDetail}></Route>}
                                        {/* SUPPLIER */}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.result)) && <Route path="/supplier/result" exact component={SupplierResult}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.edit)) && <Route path="/supplier/edit/:supplierId" exact component={SupplierEdit}></Route>}
                                        {/* SUPPLIER.product */}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.product_result)) && <Route path="/supplier/product_result" exact component={SupplierProductResult}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.product_detail)) && <Route path="/supplier/product_detail/:productId" exact component={SupplierProductDetail}></Route>}
                                        {/* SUPPLIER.order */}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_result)) && <Route path="/supplier/order_result" exact component={SupplierOrderResult}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_add)) && <Route path="/supplier/order_add/:productId" exact component={SupplierOrderAdd}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_edit)) && <Route path="/supplier/order_edit/:orderId" exact component={SupplierOrderEdit}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_cancel)) && <Route path="/supplier/order_cancel/:orderId" exact component={SupplierOrderCancel}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_detail)) && <Route path="/supplier/order_detail/:orderId" exact component={SupplierOrderDetail}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.order_fee_detail)) && <Route path="/supplier/order_fee_detail/:feeId" exact component={SupplierOrderFeeDetail}></Route>}
                                        {/* SUPPLIER.message */}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.message_result)) && <Route path="/supplier/message_result" exact component={SupplierMessageResult}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.supplier && this.props.loginUser.userPermissions.supplier.message_detail)) && <Route path="/supplier/message_detail/:messageId" exact component={SupplierMessageDetail}></Route>}
                                        {/* FINANCE */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.currency)) && <Route path="/finance/currency" exact component={FinanceCurrency}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.payment)) && <Route path="/finance/payment" exact component={FinancePayment}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.file_result)) && <Route path="/finance/file_result" exact component={FinanceFileResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.channel_account_result)) && <Route path="/finance/channel_account_result" exact component={FinanceChannelAccountResult}></Route>}
                                        {[2, 3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.account_result)) && <Route path="/finance/account_result" exact component={FinanceAccountResult}></Route>}
                                        {[2, 3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.account_add)) && <Route path="/finance/account_add" exact component={FinanceAccountAdd}></Route>}
                                        {[2, 3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.account_edit)) && <Route path="/finance/account_edit/:accountId" exact component={FinanceAccountEdit}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.receivable_result)) && <Route path="/finance/receivable_result" exact component={FinanceReceivableResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.bill_receivable_result)) && <Route path="/finance/bill_receivable_result" exact component={FinanceBillReceivableResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.bill_confirm)) && <Route path="/finance/bill_confirm/:billId" exact component={FinanceBillConfirm}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.payable_result)) && <Route path="/finance/payable_result" exact component={FinancePayableResult}></Route>}
                                        {this.props.loginUser.merchantAgent && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.finance && this.props.loginUser.userPermissions.finance.bill_payable_result)) && <Route path="/finance/bill_payable_result" exact component={FinanceBillPayableResult}></Route>}
                                        {/* USER */}
                                        <Route path="/user/result" exact component={UserResult}></Route>
                                        {/* RESOURCE */}
                                        {/* RESOURCE.hotel */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.resource && this.props.loginUser.userPermissions.resource.hotel_result)) && <Route path="/resource/hotel_result" exact component={ResourceHotelResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.resource && this.props.loginUser.userPermissions.resource.hotel_add)) && <Route path="/resource/hotel_add" exact component={ResourceHotelAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.resource && this.props.loginUser.userPermissions.resource.hotel_edit)) && <Route path="/resource/hotel_edit/:hotelId" exact component={ResourceHotelEdit}></Route>}
                                        {/* INFORMATION */}
                                        {/* INFORMATION.tag */}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.tag_result)) && <Route path="/information/tag_result" exact component={InformationTagResult}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.tag_add)) && <Route path="/information/tag_add" exact component={InformationTagAdd}></Route>}
                                        {[5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.tag_edit)) && <Route path="/information/tag_edit/:tagId" exact component={InformationTagEdit}></Route>}
                                        {/* INFORMATION.band */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.band_result)) && <Route path="/information/band_result" exact component={InformationBandResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.band_add)) && <Route path="/information/band_add" exact component={InformationBandAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.band_edit)) && <Route path="/information/band_edit/:bandId" exact component={InformationBandEdit}></Route>}
                                        {/* INFORMATION.addon */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.addon_result)) && <Route path="/information/addon_result" exact component={InformationAddonResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.addon_add)) && <Route path="/information/addon_add" exact component={InformationAddonAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.addon_edit)) && <Route path="/information/addon_edit/:addonId" exact component={InformationAddonEdit}></Route>}
                                        {/* INFORMATION.cancellation */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.cancellation_result)) && <Route path="/information/cancellation_result" exact component={InformationCancellationResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.cancellation_add)) && <Route path="/information/cancellation_add" exact component={InformationCancellationAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.cancellation_edit)) && <Route path="/information/cancellation_edit/:cancellationId" exact component={InformationCancellationEdit}></Route>}
                                        {/* INFORMATION.location */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.location_result)) && <Route path="/information/location_result" exact component={InformationLocationResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.location_add)) && <Route path="/information/location_add" exact component={InformationLocationAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.location_edit)) && <Route path="/information/location_edit/:locationId" exact component={InformationLocationEdit}></Route>}
                                        {/* INFORMATION.roomtype */}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.roomtype_result)) && <Route path="/information/roomtype_result" exact component={InformationRoomtypeResult}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.roomtype_add)) && <Route path="/information/roomtype_add" exact component={InformationRoomtypeAdd}></Route>}
                                        {[3, 5, 6, 7, 9].includes(this.props.loginUser.merchantRole) && (this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.information && this.props.loginUser.userPermissions.information.roomtype_edit)) && <Route path="/information/roomtype_edit/:roomtypeId" exact component={InformationRoomtypeEdit}></Route>}
                                        {/* SETTING */}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.company)) && <Route path="/setting/company" exact component={SettingCompany}></Route>}
                                        {/* SETTING.license */}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.license_add)) && <Route path="/setting/license_add" exact component={SettingLicenseAdd}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.license_edit)) && <Route path="/setting/license_edit/:licenseId" exact component={SettingLicenseEdit}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.license_result)) && <Route path="/setting/license_result" exact component={SettingLicenseResult}></Route>}
                                        {/* SETTING.group */}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.group_add)) && <Route path="/setting/group_add" exact component={SettingGroupAdd}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.group_edit)) && <Route path="/setting/group_edit/:groupId" exact component={SettingGroupEdit}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.group_result)) && <Route path="/setting/group_result" exact component={SettingGroupResult}></Route>}
                                        {/* SETTING.user */}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.user_add)) && <Route path="/setting/user_add" exact component={SettingUserAdd}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.user_edit)) && <Route path="/setting/user_edit/:userId" exact component={SettingUserEdit}></Route>}
                                        {(this.props.loginUser.userGroup > 0 || (this.props.loginUser.userPermissions && this.props.loginUser.userPermissions.setting && this.props.loginUser.userPermissions.setting.user_result)) && <Route path="/setting/user_result" exact component={SettingUserResult}></Route>}
                                        {/* HELP */}
                                        <Route path="/help/result" exact component={HelpResult}></Route>
                                        <Route path="/help/detail/:helpId" exact component={HelpDetail}></Route>
                                        {/* REDIRECT */}
                                        <Route path="/">
                                            <Redirect to="/"></Redirect>
                                        </Route>
                                    </Switch>
                                </>
                            ) : (
                                <Switch>
                                    <Route path="/auth/activate" exact component={AuthActivate}></Route>
                                    <Route path="/auth/login" exact component={AuthLogin}></Route>
                                    <Route path="/">
                                        <Redirect to="/auth/login"></Redirect>
                                    </Route>
                                </Switch>
                            ))}
                    </Suspense>
                </BrowserRouter>
            </ConfigProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginUser: state.currentMerchantUser.data,
        isLoginUserLoading: state.currentMerchantUser.isLoading,
        isLoginUserLogin: state.currentMerchantUser.isLogin,
    };
};

export default connect(mapStateToProps, { getCurrentMerchantUser })(withTranslation()(AppRouter));
