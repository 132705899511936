export const merchantActions = {
    // ACTION_START: "ACTION_START",
    // ACTION_SUCCESS: "ACTION_SUCCESS",
    // ACTION_FAILED: "ACTION_FAILED",
    UPDATE_MERCHANT_START: "UPDATE_MERCHANT_START",
    UPDATE_MERCHANT_BREAK: "UPDATE_MERCHANT_BREAK",
    UPDATE_MERCHANT_SUCCESS: "UPDATE_MERCHANT_SUCCESS",
    UPDATE_MERCHANT_FAILED: "UPDATE_MERCHANT_FAILED",
    UPDATE_MERCHANT_LOGO_START: "UPDATE_MERCHANT_LOGO_START",
    UPDATE_MERCHANT_LOGO_BREAK: "UPDATE_MERCHANT_LOGO_BREAK",
    UPDATE_MERCHANT_LOGO_SUCCESS: "UPDATE_MERCHANT_LOGO_SUCCESS",
    UPDATE_MERCHANT_LOGO_FAILED: "UPDATE_MERCHANT_LOGO_FAILED",
    UPDATE_MERCHANT_SEAL_START: "UPDATE_MERCHANT_SEAL_START",
    UPDATE_MERCHANT_SEAL_BREAK: "UPDATE_MERCHANT_SEAL_BREAK",
    UPDATE_MERCHANT_SEAL_SUCCESS: "UPDATE_MERCHANT_SEAL_SUCCESS",
    UPDATE_MERCHANT_SEAL_FAILED: "UPDATE_MERCHANT_SEAL_FAILED",
    UPDATE_MERCHANT_AVATAR_START: "UPDATE_MERCHANT_AVATAR_START",
    UPDATE_MERCHANT_AVATAR_BREAK: "UPDATE_MERCHANT_AVATAR_BREAK",
    UPDATE_MERCHANT_AVATAR_SUCCESS: "UPDATE_MERCHANT_AVATAR_SUCCESS",
    UPDATE_MERCHANT_AVATAR_FAILED: "UPDATE_MERCHANT_AVATAR_FAILED",
    UPDATE_MERCHANT_BANNER_START: "UPDATE_MERCHANT_BANNER_START",
    UPDATE_MERCHANT_BANNER_BREAK: "UPDATE_MERCHANT_BANNER_BREAK",
    UPDATE_MERCHANT_BANNER_SUCCESS: "UPDATE_MERCHANT_BANNER_SUCCESS",
    UPDATE_MERCHANT_BANNER_FAILED: "UPDATE_MERCHANT_BANNER_FAILED",
    UPDATE_MERCHANT_CURRENCY_RATE_START: "UPDATE_MERCHANT_CURRENCY_RATE_START",
    UPDATE_MERCHANT_CURRENCY_RATE_BREAK: "UPDATE_MERCHANT_CURRENCY_RATE_BREAK",
    UPDATE_MERCHANT_CURRENCY_RATE_SUCCESS: "UPDATE_MERCHANT_CURRENCY_RATE_SUCCESS",
    UPDATE_MERCHANT_CURRENCY_RATE_FAILED: "UPDATE_MERCHANT_CURRENCY_RATE_FAILED",
    SWITCH_MERCHANT_START: "SWITCH_MERCHANT_START",
    SWITCH_MERCHANT_BREAK: "SWITCH_MERCHANT_BREAK",
    SWITCH_MERCHANT_SUCCESS: "SWITCH_MERCHANT_SUCCESS",
    SWITCH_MERCHANT_FAILED: "SWITCH_MERCHANT_FAILED",
    GET_MERCHANT_ALL_START: "GET_MERCHANT_ALL_START",
    GET_MERCHANT_ALL_BREAK: "GET_MERCHANT_ALL_BREAK",
    GET_MERCHANT_ALL_SUCCESS: "GET_MERCHANT_ALL_SUCCESS",
    GET_MERCHANT_ALL_FAILED: "GET_MERCHANT_ALL_FAILED",
    GET_CURRENT_MERCHANT_USER_START: "GET_CURRENT_MERCHANT_USER_START",
    GET_CURRENT_MERCHANT_USER_BREAK: "GET_CURRENT_MERCHANT_USER_BREAK",
    GET_CURRENT_MERCHANT_USER_SUCCESS: "GET_CURRENT_MERCHANT_USER_SUCCESS",
    GET_CURRENT_MERCHANT_USER_FAILED: "GET_CURRENT_MERCHANT_USER_FAILED",
    GET_MERCHANT_USER_DETAIL_START: "GET_MERCHANT_USER_DETAIL_START",
    GET_MERCHANT_USER_DETAIL_BREAK: "GET_MERCHANT_USER_DETAIL_BREAK",
    GET_MERCHANT_USER_DETAIL_SUCCESS: "GET_MERCHANT_USER_DETAIL_SUCCESS",
    GET_MERCHANT_USER_DETAIL_FAILED: "GET_MERCHANT_USER_DETAIL_FAILED",
    MERCHANT_USER_LOGIN_START: "MERCHANT_USER_LOGIN_START",
    MERCHANT_USER_LOGIN_SUCCESS: "MERCHANT_USER_LOGIN_SUCCESS",
    MERCHANT_USER_LOGIN_FAILED: "MERCHANT_USER_LOGIN_FAILED",
    MERCHANT_USER_LOGOUT_START: "MERCHANT_USER_LOGOUT_START",
    MERCHANT_USER_LOGOUT_SUCCESS: "MERCHANT_USER_LOGOUT_SUCCESS",
    MERCHANT_USER_LOGOUT_FAILED: "MERCHANT_USER_LOGOUT_FAILED",
    MERCHANT_USER_ACTIVATE_VALIDATE_START: "MERCHANT_USER_ACTIVATE_VALIDATE_START",
    MERCHANT_USER_ACTIVATE_VALIDATE_SUCCESS: "MERCHANT_USER_ACTIVATE_VALIDATE_SUCCESS",
    MERCHANT_USER_ACTIVATE_VALIDATE_FAILED: "MERCHANT_USER_ACTIVATE_VALIDATE_FAILED",
    MERCHANT_USER_ACTIVATE_START: "MERCHANT_USER_ACTIVATE_START",
    MERCHANT_USER_ACTIVATE_SUCCESS: "MERCHANT_USER_ACTIVATE_SUCCESS",
    MERCHANT_USER_ACTIVATE_FAILED: "MERCHANT_USER_ACTIVATE_FAILED",
    UPDATE_MERCHANT_USER_PHOTO_START: "UPDATE_MERCHANT_USER_PHOTO_START",
    UPDATE_MERCHANT_USER_PHOTO_BREAK: "UPDATE_MERCHANT_USER_PHOTO_BREAK",
    UPDATE_MERCHANT_USER_PHOTO_SUCCESS: "UPDATE_MERCHANT_USER_PHOTO_SUCCESS",
    UPDATE_MERCHANT_USER_PHOTO_FAILED: "UPDATE_MERCHANT_USER_PHOTO_FAILED",
    UPDATE_MERCHANT_USER_PROFILE_START: "UPDATE_MERCHANT_USER_PROFILE_START",
    UPDATE_MERCHANT_USER_PROFILE_BREAK: "UPDATE_MERCHANT_USER_PROFILE_BREAK",
    UPDATE_MERCHANT_USER_PROFILE_SUCCESS: "UPDATE_MERCHANT_USER_PROFILE_SUCCESS",
    UPDATE_MERCHANT_USER_PROFILE_FAILED: "UPDATE_MERCHANT_USER_PROFILE_FAILED",
    CHANGE_MERCHANT_USER_PASSWORD_START: "CHANGE_MERCHANT_USER_PASSWORD_START",
    CHANGE_MERCHANT_USER_PASSWORD_BREAK: "CHANGE_MERCHANT_USER_PASSWORD_BREAK",
    CHANGE_MERCHANT_USER_PASSWORD_SUCCESS: "CHANGE_MERCHANT_USER_PASSWORD_SUCCESS",
    CHANGE_MERCHANT_USER_PASSWORD_FAILED: "CHANGE_MERCHANT_USER_PASSWORD_FAILED",
    GET_MERCHANT_USER_PAGE_START: "GET_MERCHANT_USER_PAGE_START",
    GET_MERCHANT_USER_PAGE_BREAK: "GET_MERCHANT_USER_PAGE_BREAK",
    GET_MERCHANT_USER_PAGE_SUCCESS: "GET_MERCHANT_USER_PAGE_SUCCESS",
    GET_MERCHANT_USER_PAGE_FAILED: "GET_MERCHANT_USER_PAGE_FAILED",
    CREATE_MERCHANT_USER_START: "CREATE_MERCHANT_USER_START",
    CREATE_MERCHANT_USER_BREAK: "CREATE_MERCHANT_USER_BREAK",
    CREATE_MERCHANT_USER_SUCCESS: "CREATE_MERCHANT_USER_SUCCESS",
    CREATE_MERCHANT_USER_FAILED: "CREATE_MERCHANT_USER_FAILED",
    UPDATE_MERCHANT_USER_START: "UPDATE_MERCHANT_USER_START",
    UPDATE_MERCHANT_USER_BREAK: "UPDATE_MERCHANT_USER_BREAK",
    UPDATE_MERCHANT_USER_SUCCESS: "UPDATE_MERCHANT_USER_SUCCESS",
    UPDATE_MERCHANT_USER_FAILED: "UPDATE_MERCHANT_USER_FAILED",
    DELETE_MERCHANT_USER_START: "DELETE_MERCHANT_USER_START",
    DELETE_MERCHANT_USER_BREAK: "DELETE_MERCHANT_USER_BREAK",
    DELETE_MERCHANT_USER_SUCCESS: "DELETE_MERCHANT_USER_SUCCESS",
    DELETE_MERCHANT_USER_FAILED: "DELETE_MERCHANT_USER_FAILED",
    GET_MERCHANT_USER_GROUP_DETAIL_START: "GET_MERCHANT_USER_GROUP_DETAIL_START",
    GET_MERCHANT_USER_GROUP_DETAIL_BREAK: "GET_MERCHANT_USER_GROUP_DETAIL_BREAK",
    GET_MERCHANT_USER_GROUP_DETAIL_SUCCESS: "GET_MERCHANT_USER_GROUP_DETAIL_SUCCESS",
    GET_MERCHANT_USER_GROUP_DETAIL_FAILED: "GET_MERCHANT_USER_GROUP_DETAIL_FAILED",
    GET_MERCHANT_USER_GROUP_PAGE_START: "GET_MERCHANT_USER_GROUP_PAGE_START",
    GET_MERCHANT_USER_GROUP_PAGE_BREAK: "GET_MERCHANT_USER_GROUP_PAGE_BREAK",
    GET_MERCHANT_USER_GROUP_PAGE_SUCCESS: "GET_MERCHANT_USER_GROUP_PAGE_SUCCESS",
    GET_MERCHANT_USER_GROUP_PAGE_FAILED: "GET_MERCHANT_USER_GROUP_PAGE_FAILED",
    CREATE_MERCHANT_USER_GROUP_START: "CREATE_MERCHANT_USER_GROUP_START",
    CREATE_MERCHANT_USER_GROUP_BREAK: "CREATE_MERCHANT_USER_GROUP_BREAK",
    CREATE_MERCHANT_USER_GROUP_SUCCESS: "CREATE_MERCHANT_USER_GROUP_SUCCESS",
    CREATE_MERCHANT_USER_GROUP_FAILED: "CREATE_MERCHANT_USER_GROUP_FAILED",
    UPDATE_MERCHANT_USER_GROUP_START: "UPDATE_MERCHANT_USER_GROUP_START",
    UPDATE_MERCHANT_USER_GROUP_BREAK: "UPDATE_MERCHANT_USER_GROUP_BREAK",
    UPDATE_MERCHANT_USER_GROUP_SUCCESS: "UPDATE_MERCHANT_USER_GROUP_SUCCESS",
    UPDATE_MERCHANT_USER_GROUP_FAILED: "UPDATE_MERCHANT_USER_GROUP_FAILED",
    DELETE_MERCHANT_USER_GROUP_START: "DELETE_MERCHANT_USER_GROUP_START",
    DELETE_MERCHANT_USER_GROUP_BREAK: "DELETE_MERCHANT_USER_GROUP_BREAK",
    DELETE_MERCHANT_USER_GROUP_SUCCESS: "DELETE_MERCHANT_USER_GROUP_SUCCESS",
    DELETE_MERCHANT_USER_GROUP_FAILED: "DELETE_MERCHANT_USER_GROUP_FAILED",
    GET_MERCHANT_GROUP_DETAIL_START: "GET_MERCHANT_GROUP_DETAIL_START",
    GET_MERCHANT_GROUP_DETAIL_BREAK: "GET_MERCHANT_GROUP_DETAIL_BREAK",
    GET_MERCHANT_GROUP_DETAIL_SUCCESS: "GET_MERCHANT_GROUP_DETAIL_SUCCESS",
    GET_MERCHANT_GROUP_DETAIL_FAILED: "GET_MERCHANT_GROUP_DETAIL_FAILED",
    GET_MERCHANT_GROUP_PAGE_START: "GET_MERCHANT_GROUP_PAGE_START",
    GET_MERCHANT_GROUP_PAGE_BREAK: "GET_MERCHANT_GROUP_PAGE_BREAK",
    GET_MERCHANT_GROUP_PAGE_SUCCESS: "GET_MERCHANT_GROUP_PAGE_SUCCESS",
    GET_MERCHANT_GROUP_PAGE_FAILED: "GET_MERCHANT_GROUP_PAGE_FAILED",
    CREATE_MERCHANT_GROUP_START: "CREATE_MERCHANT_GROUP_START",
    CREATE_MERCHANT_GROUP_BREAK: "CREATE_MERCHANT_GROUP_BREAK",
    CREATE_MERCHANT_GROUP_SUCCESS: "CREATE_MERCHANT_GROUP_SUCCESS",
    CREATE_MERCHANT_GROUP_FAILED: "CREATE_MERCHANT_GROUP_FAILED",
    UPDATE_MERCHANT_GROUP_START: "UPDATE_MERCHANT_GROUP_START",
    UPDATE_MERCHANT_GROUP_BREAK: "UPDATE_MERCHANT_GROUP_BREAK",
    UPDATE_MERCHANT_GROUP_SUCCESS: "UPDATE_MERCHANT_GROUP_SUCCESS",
    UPDATE_MERCHANT_GROUP_FAILED: "UPDATE_MERCHANT_GROUP_FAILED",
    DELETE_MERCHANT_GROUP_START: "DELETE_MERCHANT_GROUP_START",
    DELETE_MERCHANT_GROUP_BREAK: "DELETE_MERCHANT_GROUP_BREAK",
    DELETE_MERCHANT_GROUP_SUCCESS: "DELETE_MERCHANT_GROUP_SUCCESS",
    DELETE_MERCHANT_GROUP_FAILED: "DELETE_MERCHANT_GROUP_FAILED",
    GET_AGENT_MERCHANT_DETAIL_START: "GET_AGENT_MERCHANT_DETAIL_START",
    GET_AGENT_MERCHANT_DETAIL_BREAK: "GET_AGENT_MERCHANT_DETAIL_BREAK",
    GET_AGENT_MERCHANT_DETAIL_SUCCESS: "GET_AGENT_MERCHANT_DETAIL_SUCCESS",
    GET_AGENT_MERCHANT_DETAIL_FAILED: "GET_AGENT_MERCHANT_DETAIL_FAILED",
    GET_AGENT_MERCHANT_PAGE_START: "GET_AGENT_MERCHANT_PAGE_START",
    GET_AGENT_MERCHANT_PAGE_BREAK: "GET_AGENT_MERCHANT_PAGE_BREAK",
    GET_AGENT_MERCHANT_PAGE_SUCCESS: "GET_AGENT_MERCHANT_PAGE_SUCCESS",
    GET_AGENT_MERCHANT_PAGE_FAILED: "GET_AGENT_MERCHANT_PAGE_FAILED",
    CREATE_AGENT_MERCHANT_START: "CREATE_AGENT_MERCHANT_START",
    CREATE_AGENT_MERCHANT_BREAK: "CREATE_AGENT_MERCHANT_BREAK",
    CREATE_AGENT_MERCHANT_SUCCESS: "CREATE_AGENT_MERCHANT_SUCCESS",
    CREATE_AGENT_MERCHANT_FAILED: "CREATE_AGENT_MERCHANT_FAILED",
    UPDATE_AGENT_MERCHANT_START: "UPDATE_AGENT_MERCHANT_START",
    UPDATE_AGENT_MERCHANT_BREAK: "UPDATE_AGENT_MERCHANT_BREAK",
    UPDATE_AGENT_MERCHANT_SUCCESS: "UPDATE_AGENT_MERCHANT_SUCCESS",
    UPDATE_AGENT_MERCHANT_FAILED: "UPDATE_AGENT_MERCHANT_FAILED",
    TERMINATE_AGENT_MERCHANT_START: "TERMINATE_AGENT_MERCHANT_START",
    TERMINATE_AGENT_MERCHANT_BREAK: "TERMINATE_AGENT_MERCHANT_BREAK",
    TERMINATE_AGENT_MERCHANT_SUCCESS: "TERMINATE_AGENT_MERCHANT_SUCCESS",
    TERMINATE_AGENT_MERCHANT_FAILED: "TERMINATE_AGENT_MERCHANT_FAILED",
    SEND_AUTH_AGENT_MERCHANT_START: "SEND_AUTH_AGENT_MERCHANT_START",
    SEND_AUTH_AGENT_MERCHANT_BREAK: "SEND_AUTH_AGENT_MERCHANT_BREAK",
    SEND_AUTH_AGENT_MERCHANT_SUCCESS: "SEND_AUTH_AGENT_MERCHANT_SUCCESS",
    SEND_AUTH_AGENT_MERCHANT_FAILED: "SEND_AUTH_AGENT_MERCHANT_FAILED",
    GET_AGENT_PRODUCT_RULE_DETAIL_START: "GET_AGENT_PRODUCT_RULE_DETAIL_START",
    GET_AGENT_PRODUCT_RULE_DETAIL_BREAK: "GET_AGENT_PRODUCT_RULE_DETAIL_BREAK",
    GET_AGENT_PRODUCT_RULE_DETAIL_SUCCESS: "GET_AGENT_PRODUCT_RULE_DETAIL_SUCCESS",
    GET_AGENT_PRODUCT_RULE_DETAIL_FAILED: "GET_AGENT_PRODUCT_RULE_DETAIL_FAILED",
    GET_AGENT_PRODUCT_RULE_PAGE_START: "GET_AGENT_PRODUCT_RULE_PAGE_START",
    GET_AGENT_PRODUCT_RULE_PAGE_BREAK: "GET_AGENT_PRODUCT_RULE_PAGE_BREAK",
    GET_AGENT_PRODUCT_RULE_PAGE_SUCCESS: "GET_AGENT_PRODUCT_RULE_PAGE_SUCCESS",
    GET_AGENT_PRODUCT_RULE_PAGE_FAILED: "GET_AGENT_PRODUCT_RULE_PAGE_FAILED",
    BATCH_CREATE_AGENT_PRODUCT_RULE_START: "BATCH_CREATE_AGENT_PRODUCT_RULE_START",
    BATCH_CREATE_AGENT_PRODUCT_RULE_BREAK: "BATCH_CREATE_AGENT_PRODUCT_RULE_BREAK",
    BATCH_CREATE_AGENT_PRODUCT_RULE_SUCCESS: "BATCH_CREATE_AGENT_PRODUCT_RULE_SUCCESS",
    BATCH_CREATE_AGENT_PRODUCT_RULE_FAILED: "BATCH_CREATE_AGENT_PRODUCT_RULE_FAILED",
    CREATE_AGENT_PRODUCT_RULE_START: "CREATE_AGENT_PRODUCT_RULE_START",
    CREATE_AGENT_PRODUCT_RULE_BREAK: "CREATE_AGENT_PRODUCT_RULE_BREAK",
    CREATE_AGENT_PRODUCT_RULE_SUCCESS: "CREATE_AGENT_PRODUCT_RULE_SUCCESS",
    CREATE_AGENT_PRODUCT_RULE_FAILED: "CREATE_AGENT_PRODUCT_RULE_FAILED",
    UPDATE_AGENT_PRODUCT_RULE_START: "UPDATE_AGENT_PRODUCT_RULE_START",
    UPDATE_AGENT_PRODUCT_RULE_BREAK: "UPDATE_AGENT_PRODUCT_RULE_BREAK",
    UPDATE_AGENT_PRODUCT_RULE_SUCCESS: "UPDATE_AGENT_PRODUCT_RULE_SUCCESS",
    UPDATE_AGENT_PRODUCT_RULE_FAILED: "UPDATE_AGENT_PRODUCT_RULE_FAILED",
    DELETE_AGENT_PRODUCT_RULE_START: "DELETE_AGENT_PRODUCT_RULE_START",
    DELETE_AGENT_PRODUCT_RULE_BREAK: "DELETE_AGENT_PRODUCT_RULE_BREAK",
    DELETE_AGENT_PRODUCT_RULE_SUCCESS: "DELETE_AGENT_PRODUCT_RULE_SUCCESS",
    DELETE_AGENT_PRODUCT_RULE_FAILED: "DELETE_AGENT_PRODUCT_RULE_FAILED",
    GET_AGENT_ADDON_RULE_DETAIL_START: "GET_AGENT_ADDON_RULE_DETAIL_START",
    GET_AGENT_ADDON_RULE_DETAIL_BREAK: "GET_AGENT_ADDON_RULE_DETAIL_BREAK",
    GET_AGENT_ADDON_RULE_DETAIL_SUCCESS: "GET_AGENT_ADDON_RULE_DETAIL_SUCCESS",
    GET_AGENT_ADDON_RULE_DETAIL_FAILED: "GET_AGENT_ADDON_RULE_DETAIL_FAILED",
    GET_AGENT_ADDON_RULE_PAGE_START: "GET_AGENT_ADDON_RULE_PAGE_START",
    GET_AGENT_ADDON_RULE_PAGE_BREAK: "GET_AGENT_ADDON_RULE_PAGE_BREAK",
    GET_AGENT_ADDON_RULE_PAGE_SUCCESS: "GET_AGENT_ADDON_RULE_PAGE_SUCCESS",
    GET_AGENT_ADDON_RULE_PAGE_FAILED: "GET_AGENT_ADDON_RULE_PAGE_FAILED",
    CREATE_AGENT_ADDON_RULE_START: "CREATE_AGENT_ADDON_RULE_START",
    CREATE_AGENT_ADDON_RULE_BREAK: "CREATE_AGENT_ADDON_RULE_BREAK",
    CREATE_AGENT_ADDON_RULE_SUCCESS: "CREATE_AGENT_ADDON_RULE_SUCCESS",
    CREATE_AGENT_ADDON_RULE_FAILED: "CREATE_AGENT_ADDON_RULE_FAILED",
    UPDATE_AGENT_ADDON_RULE_START: "UPDATE_AGENT_ADDON_RULE_START",
    UPDATE_AGENT_ADDON_RULE_BREAK: "UPDATE_AGENT_ADDON_RULE_BREAK",
    UPDATE_AGENT_ADDON_RULE_SUCCESS: "UPDATE_AGENT_ADDON_RULE_SUCCESS",
    UPDATE_AGENT_ADDON_RULE_FAILED: "UPDATE_AGENT_ADDON_RULE_FAILED",
    DELETE_AGENT_ADDON_RULE_START: "DELETE_AGENT_ADDON_RULE_START",
    DELETE_AGENT_ADDON_RULE_BREAK: "DELETE_AGENT_ADDON_RULE_BREAK",
    DELETE_AGENT_ADDON_RULE_SUCCESS: "DELETE_AGENT_ADDON_RULE_SUCCESS",
    DELETE_AGENT_ADDON_RULE_FAILED: "DELETE_AGENT_ADDON_RULE_FAILED",
    GET_MERCHANT_LOG_PAGE_START: "GET_MERCHANT_LOG_PAGE_START",
    GET_MERCHANT_LOG_PAGE_BREAK: "GET_MERCHANT_LOG_PAGE_BREAK",
    GET_MERCHANT_LOG_PAGE_SUCCESS: "GET_MERCHANT_LOG_PAGE_SUCCESS",
    GET_MERCHANT_LOG_PAGE_FAILED: "GET_MERCHANT_LOG_PAGE_FAILED",
    GET_MERCHANT_ACCOUNT_DETAIL_START: "GET_MERCHANT_ACCOUNT_DETAIL_START",
    GET_MERCHANT_ACCOUNT_DETAIL_BREAK: "GET_MERCHANT_ACCOUNT_DETAIL_BREAK",
    GET_MERCHANT_ACCOUNT_DETAIL_SUCCESS: "GET_MERCHANT_ACCOUNT_DETAIL_SUCCESS",
    GET_MERCHANT_ACCOUNT_DETAIL_FAILED: "GET_MERCHANT_ACCOUNT_DETAIL_FAILED",
    GET_MERCHANT_ACCOUNT_PAGE_START: "GET_MERCHANT_ACCOUNT_PAGE_START",
    GET_MERCHANT_ACCOUNT_PAGE_BREAK: "GET_MERCHANT_ACCOUNT_PAGE_BREAK",
    GET_MERCHANT_ACCOUNT_PAGE_SUCCESS: "GET_MERCHANT_ACCOUNT_PAGE_SUCCESS",
    GET_MERCHANT_ACCOUNT_PAGE_FAILED: "GET_MERCHANT_ACCOUNT_PAGE_FAILED",
    CREATE_MERCHANT_ACCOUNT_START: "CREATE_MERCHANT_ACCOUNT_START",
    CREATE_MERCHANT_ACCOUNT_BREAK: "CREATE_MERCHANT_ACCOUNT_BREAK",
    CREATE_MERCHANT_ACCOUNT_SUCCESS: "CREATE_MERCHANT_ACCOUNT_SUCCESS",
    CREATE_MERCHANT_ACCOUNT_FAILED: "CREATE_MERCHANT_ACCOUNT_FAILED",
    UPDATE_MERCHANT_ACCOUNT_START: "UPDATE_MERCHANT_ACCOUNT_START",
    UPDATE_MERCHANT_ACCOUNT_BREAK: "UPDATE_MERCHANT_ACCOUNT_BREAK",
    UPDATE_MERCHANT_ACCOUNT_SUCCESS: "UPDATE_MERCHANT_ACCOUNT_SUCCESS",
    UPDATE_MERCHANT_ACCOUNT_FAILED: "UPDATE_MERCHANT_ACCOUNT_FAILED",
    DELETE_MERCHANT_ACCOUNT_START: "DELETE_MERCHANT_ACCOUNT_START",
    DELETE_MERCHANT_ACCOUNT_BREAK: "DELETE_MERCHANT_ACCOUNT_BREAK",
    DELETE_MERCHANT_ACCOUNT_SUCCESS: "DELETE_MERCHANT_ACCOUNT_SUCCESS",
    DELETE_MERCHANT_ACCOUNT_FAILED: "DELETE_MERCHANT_ACCOUNT_FAILED",
    GET_MERCHANT_MESSAGE_DETAIL_START: "GET_MERCHANT_MESSAGE_DETAIL_START",
    GET_MERCHANT_MESSAGE_DETAIL_BREAK: "GET_MERCHANT_MESSAGE_DETAIL_BREAK",
    GET_MERCHANT_MESSAGE_DETAIL_SUCCESS: "GET_MERCHANT_MESSAGE_DETAIL_SUCCESS",
    GET_MERCHANT_MESSAGE_DETAIL_FAILED: "GET_MERCHANT_MESSAGE_DETAIL_FAILED",
    GET_MERCHANT_MESSAGE_PAGE_START: "GET_MERCHANT_MESSAGE_PAGE_START",
    GET_MERCHANT_MESSAGE_PAGE_BREAK: "GET_MERCHANT_MESSAGE_PAGE_BREAK",
    GET_MERCHANT_MESSAGE_PAGE_SUCCESS: "GET_MERCHANT_MESSAGE_PAGE_SUCCESS",
    GET_MERCHANT_MESSAGE_PAGE_FAILED: "GET_MERCHANT_MESSAGE_PAGE_FAILED",
    CREATE_MERCHANT_MESSAGE_START: "CREATE_MERCHANT_MESSAGE_START",
    CREATE_MERCHANT_MESSAGE_BREAK: "CREATE_MERCHANT_MESSAGE_BREAK",
    CREATE_MERCHANT_MESSAGE_SUCCESS: "CREATE_MERCHANT_MESSAGE_SUCCESS",
    CREATE_MERCHANT_MESSAGE_FAILED: "CREATE_MERCHANT_MESSAGE_FAILED",
    UPDATE_MERCHANT_MESSAGE_START: "UPDATE_MERCHANT_MESSAGE_START",
    UPDATE_MERCHANT_MESSAGE_BREAK: "UPDATE_MERCHANT_MESSAGE_BREAK",
    UPDATE_MERCHANT_MESSAGE_SUCCESS: "UPDATE_MERCHANT_MESSAGE_SUCCESS",
    UPDATE_MERCHANT_MESSAGE_FAILED: "UPDATE_MERCHANT_MESSAGE_FAILED",
    UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_START: "UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_START",
    UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_BREAK: "UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_BREAK",
    UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_SUCCESS: "UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_SUCCESS",
    UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_FAILED: "UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_FAILED",
    DELETE_MERCHANT_MESSAGE_START: "DELETE_MERCHANT_MESSAGE_START",
    DELETE_MERCHANT_MESSAGE_BREAK: "DELETE_MERCHANT_MESSAGE_BREAK",
    DELETE_MERCHANT_MESSAGE_SUCCESS: "DELETE_MERCHANT_MESSAGE_SUCCESS",
    DELETE_MERCHANT_MESSAGE_FAILED: "DELETE_MERCHANT_MESSAGE_FAILED",
    GET_MERCHANT_LICENSE_DETAIL_START: "GET_MERCHANT_LICENSE_DETAIL_START",
    GET_MERCHANT_LICENSE_DETAIL_BREAK: "GET_MERCHANT_LICENSE_DETAIL_BREAK",
    GET_MERCHANT_LICENSE_DETAIL_SUCCESS: "GET_MERCHANT_LICENSE_DETAIL_SUCCESS",
    GET_MERCHANT_LICENSE_DETAIL_FAILED: "GET_MERCHANT_LICENSE_DETAIL_FAILED",
    GET_MERCHANT_LICENSE_PAGE_START: "GET_MERCHANT_LICENSE_PAGE_START",
    GET_MERCHANT_LICENSE_PAGE_BREAK: "GET_MERCHANT_LICENSE_PAGE_BREAK",
    GET_MERCHANT_LICENSE_PAGE_SUCCESS: "GET_MERCHANT_LICENSE_PAGE_SUCCESS",
    GET_MERCHANT_LICENSE_PAGE_FAILED: "GET_MERCHANT_LICENSE_PAGE_FAILED",
    CREATE_MERCHANT_LICENSE_START: "CREATE_MERCHANT_LICENSE_START",
    CREATE_MERCHANT_LICENSE_BREAK: "CREATE_MERCHANT_LICENSE_BREAK",
    CREATE_MERCHANT_LICENSE_SUCCESS: "CREATE_MERCHANT_LICENSE_SUCCESS",
    CREATE_MERCHANT_LICENSE_FAILED: "CREATE_MERCHANT_LICENSE_FAILED",
    UPDATE_MERCHANT_LICENSE_START: "UPDATE_MERCHANT_LICENSE_START",
    UPDATE_MERCHANT_LICENSE_BREAK: "UPDATE_MERCHANT_LICENSE_BREAK",
    UPDATE_MERCHANT_LICENSE_SUCCESS: "UPDATE_MERCHANT_LICENSE_SUCCESS",
    UPDATE_MERCHANT_LICENSE_FAILED: "UPDATE_MERCHANT_LICENSE_FAILED",
    DELETE_MERCHANT_LICENSE_START: "DELETE_MERCHANT_LICENSE_START",
    DELETE_MERCHANT_LICENSE_BREAK: "DELETE_MERCHANT_LICENSE_BREAK",
    DELETE_MERCHANT_LICENSE_SUCCESS: "DELETE_MERCHANT_LICENSE_SUCCESS",
    DELETE_MERCHANT_LICENSE_FAILED: "DELETE_MERCHANT_LICENSE_FAILED",
    UPLOAD_MERCHANT_LICENSE_START: "UPLOAD_MERCHANT_LICENSE_START",
    UPLOAD_MERCHANT_LICENSE_BREAK: "UPLOAD_MERCHANT_LICENSE_BREAK",
    UPLOAD_MERCHANT_LICENSE_SUCCESS: "UPLOAD_MERCHANT_LICENSE_SUCCESS",
    UPLOAD_MERCHANT_LICENSE_FAILED: "UPLOAD_MERCHANT_LICENSE_FAILED",
    GET_MERCHANT_FILE_DETAIL_START: "GET_MERCHANT_FILE_DETAIL_START",
    GET_MERCHANT_FILE_DETAIL_BREAK: "GET_MERCHANT_FILE_DETAIL_BREAK",
    GET_MERCHANT_FILE_DETAIL_SUCCESS: "GET_MERCHANT_FILE_DETAIL_SUCCESS",
    GET_MERCHANT_FILE_DETAIL_FAILED: "GET_MERCHANT_FILE_DETAIL_FAILED",
    GET_MERCHANT_FILE_PAGE_START: "GET_MERCHANT_FILE_PAGE_START",
    GET_MERCHANT_FILE_PAGE_BREAK: "GET_MERCHANT_FILE_PAGE_BREAK",
    GET_MERCHANT_FILE_PAGE_SUCCESS: "GET_MERCHANT_FILE_PAGE_SUCCESS",
    GET_MERCHANT_FILE_PAGE_FAILED: "GET_MERCHANT_FILE_PAGE_FAILED",
    CREATE_MERCHANT_FILE_START: "CREATE_MERCHANT_FILE_START",
    CREATE_MERCHANT_FILE_BREAK: "CREATE_MERCHANT_FILE_BREAK",
    CREATE_MERCHANT_FILE_SUCCESS: "CREATE_MERCHANT_FILE_SUCCESS",
    CREATE_MERCHANT_FILE_FAILED: "CREATE_MERCHANT_FILE_FAILED",
    UPDATE_MERCHANT_FILE_START: "UPDATE_MERCHANT_FILE_START",
    UPDATE_MERCHANT_FILE_BREAK: "UPDATE_MERCHANT_FILE_BREAK",
    UPDATE_MERCHANT_FILE_SUCCESS: "UPDATE_MERCHANT_FILE_SUCCESS",
    UPDATE_MERCHANT_FILE_FAILED: "UPDATE_MERCHANT_FILE_FAILED",
    DELETE_MERCHANT_FILE_START: "DELETE_MERCHANT_FILE_START",
    DELETE_MERCHANT_FILE_BREAK: "DELETE_MERCHANT_FILE_BREAK",
    DELETE_MERCHANT_FILE_SUCCESS: "DELETE_MERCHANT_FILE_SUCCESS",
    DELETE_MERCHANT_FILE_FAILED: "DELETE_MERCHANT_FILE_FAILED",
    UPLOAD_MERCHANT_FILE_START: "UPLOAD_MERCHANT_FILE_START",
    UPLOAD_MERCHANT_FILE_BREAK: "UPLOAD_MERCHANT_FILE_BREAK",
    UPLOAD_MERCHANT_FILE_SUCCESS: "UPLOAD_MERCHANT_FILE_SUCCESS",
    UPLOAD_MERCHANT_FILE_FAILED: "UPLOAD_MERCHANT_FILE_FAILED",
    DOWNLOAD_MERCHANT_FILE_START: "DOWNLOAD_MERCHANT_FILE_START",
    DOWNLOAD_MERCHANT_FILE_BREAK: "DOWNLOAD_MERCHANT_FILE_BREAK",
    DOWNLOAD_MERCHANT_FILE_SUCCESS: "DOWNLOAD_MERCHANT_FILE_SUCCESS",
    DOWNLOAD_MERCHANT_FILE_FAILED: "DOWNLOAD_MERCHANT_FILE_FAILED",
};

export const informationActions = {
    // ACTION_START: "ACTION_START",
    // ACTION_SUCCESS: "ACTION_SUCCESS",
    // ACTION_FAILED: "ACTION_FAILED",
    CREATE_BAND_START: "CREATE_BAND_START",
    CREATE_BAND_BREAK: "CREATE_BAND_BREAK",
    CREATE_BAND_SUCCESS: "CREATE_BAND_SUCCESS",
    CREATE_BAND_FAILED: "CREATE_BAND_FAILED",
    UPDATE_BAND_START: "UPDATE_BAND_START",
    UPDATE_BAND_BREAK: "UPDATE_BAND_BREAK",
    UPDATE_BAND_SUCCESS: "UPDATE_BAND_SUCCESS",
    UPDATE_BAND_FAILED: "UPDATE_BAND_FAILED",
    DELETE_BAND_START: "DELETE_BAND_START",
    DELETE_BAND_BREAK: "DELETE_BAND_BREAK",
    DELETE_BAND_SUCCESS: "DELETE_BAND_SUCCESS",
    DELETE_BAND_FAILED: "DELETE_BAND_FAILED",
    GET_BAND_DETAIL_START: "GET_BAND_DETAIL_START",
    GET_BAND_DETAIL_BREAK: "GET_BAND_DETAIL_BREAK",
    GET_BAND_DETAIL_SUCCESS: "GET_BAND_DETAIL_SUCCESS",
    GET_BAND_DETAIL_FAILED: "GET_BAND_DETAIL_FAILED",
    GET_BAND_PAGE_START: "GET_BAND_PAGE_START",
    GET_BAND_PAGE_BREAK: "GET_BAND_PAGE_BREAK",
    GET_BAND_PAGE_SUCCESS: "GET_BAND_PAGE_SUCCESS",
    GET_BAND_PAGE_FAILED: "GET_BAND_PAGE_FAILED",
    CREATE_ADDON_START: "CREATE_ADDON_START",
    CREATE_ADDON_BREAK: "CREATE_ADDON_BREAK",
    CREATE_ADDON_SUCCESS: "CREATE_ADDON_SUCCESS",
    CREATE_ADDON_FAILED: "CREATE_ADDON_FAILED",
    UPDATE_ADDON_START: "UPDATE_ADDON_START",
    UPDATE_ADDON_BREAK: "UPDATE_ADDON_BREAK",
    UPDATE_ADDON_SUCCESS: "UPDATE_ADDON_SUCCESS",
    UPDATE_ADDON_FAILED: "UPDATE_ADDON_FAILED",
    DELETE_ADDON_START: "DELETE_ADDON_START",
    DELETE_ADDON_BREAK: "DELETE_ADDON_BREAK",
    DELETE_ADDON_SUCCESS: "DELETE_ADDON_SUCCESS",
    DELETE_ADDON_FAILED: "DELETE_ADDON_FAILED",
    GET_ADDON_DETAIL_START: "GET_ADDON_DETAIL_START",
    GET_ADDON_DETAIL_BREAK: "GET_ADDON_DETAIL_BREAK",
    GET_ADDON_DETAIL_SUCCESS: "GET_ADDON_DETAIL_SUCCESS",
    GET_ADDON_DETAIL_FAILED: "GET_ADDON_DETAIL_FAILED",
    GET_ADDON_PAGE_START: "GET_ADDON_PAGE_START",
    GET_ADDON_PAGE_BREAK: "GET_ADDON_PAGE_BREAK",
    GET_ADDON_PAGE_SUCCESS: "GET_ADDON_PAGE_SUCCESS",
    GET_ADDON_PAGE_FAILED: "GET_ADDON_PAGE_FAILED",
    CREATE_CANCELLATION_START: "CREATE_CANCELLATION_START",
    CREATE_CANCELLATION_BREAK: "CREATE_CANCELLATION_BREAK",
    CREATE_CANCELLATION_SUCCESS: "CREATE_CANCELLATION_SUCCESS",
    CREATE_CANCELLATION_FAILED: "CREATE_CANCELLATION_FAILED",
    UPDATE_CANCELLATION_START: "UPDATE_CANCELLATION_START",
    UPDATE_CANCELLATION_BREAK: "UPDATE_CANCELLATION_BREAK",
    UPDATE_CANCELLATION_SUCCESS: "UPDATE_CANCELLATION_SUCCESS",
    UPDATE_CANCELLATION_FAILED: "UPDATE_CANCELLATION_FAILED",
    DELETE_CANCELLATION_START: "DELETE_CANCELLATION_START",
    DELETE_CANCELLATION_BREAK: "DELETE_CANCELLATION_BREAK",
    DELETE_CANCELLATION_SUCCESS: "DELETE_CANCELLATION_SUCCESS",
    DELETE_CANCELLATION_FAILED: "DELETE_CANCELLATION_FAILED",
    GET_CANCELLATION_DETAIL_START: "GET_CANCELLATION_DETAIL_START",
    GET_CANCELLATION_DETAIL_BREAK: "GET_CANCELLATION_DETAIL_BREAK",
    GET_CANCELLATION_DETAIL_SUCCESS: "GET_CANCELLATION_DETAIL_SUCCESS",
    GET_CANCELLATION_DETAIL_FAILED: "GET_CANCELLATION_DETAIL_FAILED",
    GET_CANCELLATION_PAGE_START: "GET_CANCELLATION_PAGE_START",
    GET_CANCELLATION_PAGE_BREAK: "GET_CANCELLATION_PAGE_BREAK",
    GET_CANCELLATION_PAGE_SUCCESS: "GET_CANCELLATION_PAGE_SUCCESS",
    GET_CANCELLATION_PAGE_FAILED: "GET_CANCELLATION_PAGE_FAILED",
    CREATE_LOCATION_START: "CREATE_LOCATION_START",
    CREATE_LOCATION_BREAK: "CREATE_LOCATION_BREAK",
    CREATE_LOCATION_SUCCESS: "CREATE_LOCATION_SUCCESS",
    CREATE_LOCATION_FAILED: "CREATE_LOCATION_FAILED",
    UPDATE_LOCATION_START: "UPDATE_LOCATION_START",
    UPDATE_LOCATION_BREAK: "UPDATE_LOCATION_BREAK",
    UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
    UPDATE_LOCATION_FAILED: "UPDATE_LOCATION_FAILED",
    DELETE_LOCATION_START: "DELETE_LOCATION_START",
    DELETE_LOCATION_BREAK: "DELETE_LOCATION_BREAK",
    DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
    DELETE_LOCATION_FAILED: "DELETE_LOCATION_FAILED",
    GET_LOCATION_DETAIL_START: "GET_LOCATION_DETAIL_START",
    GET_LOCATION_DETAIL_BREAK: "GET_LOCATION_DETAIL_BREAK",
    GET_LOCATION_DETAIL_SUCCESS: "GET_LOCATION_DETAIL_SUCCESS",
    GET_LOCATION_DETAIL_FAILED: "GET_LOCATION_DETAIL_FAILED",
    GET_LOCATION_PAGE_START: "GET_LOCATION_PAGE_START",
    GET_LOCATION_PAGE_BREAK: "GET_LOCATION_PAGE_BREAK",
    GET_LOCATION_PAGE_SUCCESS: "GET_LOCATION_PAGE_SUCCESS",
    GET_LOCATION_PAGE_FAILED: "GET_LOCATION_PAGE_FAILED",
    CREATE_ROOMTYPE_START: "CREATE_ROOMTYPE_START",
    CREATE_ROOMTYPE_BREAK: "CREATE_ROOMTYPE_BREAK",
    CREATE_ROOMTYPE_SUCCESS: "CREATE_ROOMTYPE_SUCCESS",
    CREATE_ROOMTYPE_FAILED: "CREATE_ROOMTYPE_FAILED",
    UPDATE_ROOMTYPE_START: "UPDATE_ROOMTYPE_START",
    UPDATE_ROOMTYPE_BREAK: "UPDATE_ROOMTYPE_BREAK",
    UPDATE_ROOMTYPE_SUCCESS: "UPDATE_ROOMTYPE_SUCCESS",
    UPDATE_ROOMTYPE_FAILED: "UPDATE_ROOMTYPE_FAILED",
    DELETE_ROOMTYPE_START: "DELETE_ROOMTYPE_START",
    DELETE_ROOMTYPE_BREAK: "DELETE_ROOMTYPE_BREAK",
    DELETE_ROOMTYPE_SUCCESS: "DELETE_ROOMTYPE_SUCCESS",
    DELETE_ROOMTYPE_FAILED: "DELETE_ROOMTYPE_FAILED",
    GET_ROOMTYPE_DETAIL_START: "GET_ROOMTYPE_DETAIL_START",
    GET_ROOMTYPE_DETAIL_BREAK: "GET_ROOMTYPE_DETAIL_BREAK",
    GET_ROOMTYPE_DETAIL_SUCCESS: "GET_ROOMTYPE_DETAIL_SUCCESS",
    GET_ROOMTYPE_DETAIL_FAILED: "GET_ROOMTYPE_DETAIL_FAILED",
    GET_ROOMTYPE_PAGE_START: "GET_ROOMTYPE_PAGE_START",
    GET_ROOMTYPE_PAGE_BREAK: "GET_ROOMTYPE_PAGE_BREAK",
    GET_ROOMTYPE_PAGE_SUCCESS: "GET_ROOMTYPE_PAGE_SUCCESS",
    GET_ROOMTYPE_PAGE_FAILED: "GET_ROOMTYPE_PAGE_FAILED",
    CREATE_TAG_START: "CREATE_TAG_START",
    CREATE_TAG_BREAK: "CREATE_TAG_BREAK",
    CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
    CREATE_TAG_FAILED: "CREATE_TAG_FAILED",
    UPDATE_TAG_START: "UPDATE_TAG_START",
    UPDATE_TAG_BREAK: "UPDATE_TAG_BREAK",
    UPDATE_TAG_SUCCESS: "UPDATE_TAG_SUCCESS",
    UPDATE_TAG_FAILED: "UPDATE_TAG_FAILED",
    UPLOAD_TAG_IMAGE_START: "UPLOAD_TAG_IMAGE_START",
    UPLOAD_TAG_IMAGE_BREAK: "UPLOAD_TAG_IMAGE_BREAK",
    UPLOAD_TAG_IMAGE_SUCCESS: "UPLOAD_TAG_IMAGE_SUCCESS",
    UPLOAD_TAG_IMAGE_FAILED: "UPLOAD_TAG_IMAGE_FAILED",
    UPLOAD_TAG_ICON_START: "UPLOAD_TAG_ICON_START",
    UPLOAD_TAG_ICON_BREAK: "UPLOAD_TAG_ICON_BREAK",
    UPLOAD_TAG_ICON_SUCCESS: "UPLOAD_TAG_ICON_SUCCESS",
    UPLOAD_TAG_ICON_FAILED: "UPLOAD_TAG_ICON_FAILED",
    DELETE_TAG_START: "DELETE_TAG_START",
    DELETE_TAG_BREAK: "DELETE_TAG_BREAK",
    DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
    DELETE_TAG_FAILED: "DELETE_TAG_FAILED",
    GET_TAG_DETAIL_START: "GET_TAG_DETAIL_START",
    GET_TAG_DETAIL_BREAK: "GET_TAG_DETAIL_BREAK",
    GET_TAG_DETAIL_SUCCESS: "GET_TAG_DETAIL_SUCCESS",
    GET_TAG_DETAIL_FAILED: "GET_TAG_DETAIL_FAILED",
    GET_TAG_PAGE_START: "GET_TAG_PAGE_START",
    GET_TAG_PAGE_BREAK: "GET_TAG_PAGE_BREAK",
    GET_TAG_PAGE_SUCCESS: "GET_TAG_PAGE_SUCCESS",
    GET_TAG_PAGE_FAILED: "GET_TAG_PAGE_FAILED",
};

export const productActions = {
    GET_PRODUCT_PAGE_START: "GET_PRODUCT_PAGE_START",
    GET_PRODUCT_PAGE_BREAK: "GET_PRODUCT_PAGE_BREAK",
    GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
    GET_PRODUCT_PAGE_FAILED: "GET_PRODUCT_PAGE_FAILED",
    GET_PRODUCT_DETAIL_START: "GET_PRODUCT_DETAIL_START",
    GET_PRODUCT_DETAIL_BREAK: "GET_PRODUCT_DETAIL_BREAK",
    GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
    GET_PRODUCT_DETAIL_FAILED: "GET_PRODUCT_DETAIL_FAILED",
    GET_PRODUCT_QRCODE_START: "GET_PRODUCT_QRCODE_START",
    GET_PRODUCT_QRCODE_BREAK: "GET_PRODUCT_QRCODE_BREAK",
    GET_PRODUCT_QRCODE_SUCCESS: "GET_PRODUCT_QRCODE_SUCCESS",
    GET_PRODUCT_QRCODE_FAILED: "GET_PRODUCT_QRCODE_FAILED",
    UPLOAD_PRODUCT_PHOTO_START: "UPLOAD_PRODUCT_PHOTO_START",
    UPLOAD_PRODUCT_PHOTO_BREAK: "UPLOAD_PRODUCT_PHOTO_BREAK",
    UPLOAD_PRODUCT_PHOTO_SUCCESS: "UPLOAD_PRODUCT_PHOTO_SUCCESS",
    UPLOAD_PRODUCT_PHOTO_FAILED: "UPLOAD_PRODUCT_PHOTO_FAILED",
    CREATE_PRODUCT_START: "CREATE_PRODUCT_START",
    CREATE_PRODUCT_BREAK: "CREATE_PRODUCT_BREAK",
    CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
    CREATE_PRODUCT_FAILED: "CREATE_PRODUCT_FAILED",
    UPDATE_PRODUCT_START: "UPDATE_PRODUCT_START",
    UPDATE_PRODUCT_BREAK: "UPDATE_PRODUCT_BREAK",
    UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILED: "UPDATE_PRODUCT_FAILED",
    UPDATE_PRODUCT_DETAIL_START: "UPDATE_PRODUCT_DETAIL_START",
    UPDATE_PRODUCT_DETAIL_BREAK: "UPDATE_PRODUCT_DETAIL_BREAK",
    UPDATE_PRODUCT_DETAIL_SUCCESS: "UPDATE_PRODUCT_DETAIL_SUCCESS",
    UPDATE_PRODUCT_DETAIL_FAILED: "UPDATE_PRODUCT_DETAIL_FAILED",
    UPDATE_PRODUCT_ITINERARY_START: "UPDATE_PRODUCT_ITINERARY_START",
    UPDATE_PRODUCT_ITINERARY_BREAK: "UPDATE_PRODUCT_ITINERARY_BREAK",
    UPDATE_PRODUCT_ITINERARY_SUCCESS: "UPDATE_PRODUCT_ITINERARY_SUCCESS",
    UPDATE_PRODUCT_ITINERARY_FAILED: "UPDATE_PRODUCT_ITINERARY_FAILED",
    UPDATE_PRODUCT_COMBINE_START: "UPDATE_PRODUCT_COMBINE_START",
    UPDATE_PRODUCT_COMBINE_BREAK: "UPDATE_PRODUCT_COMBINE_BREAK",
    UPDATE_PRODUCT_COMBINE_SUCCESS: "UPDATE_PRODUCT_COMBINE_SUCCESS",
    UPDATE_PRODUCT_COMBINE_FAILED: "UPDATE_PRODUCT_COMBINE_FAILED",
    UPDATE_PRODUCT_INVENTORY_START: "UPDATE_PRODUCT_INVENTORY_START",
    UPDATE_PRODUCT_INVENTORY_BREAK: "UPDATE_PRODUCT_INVENTORY_BREAK",
    UPDATE_PRODUCT_INVENTORY_SUCCESS: "UPDATE_PRODUCT_INVENTORY_SUCCESS",
    UPDATE_PRODUCT_INVENTORY_FAILED: "UPDATE_PRODUCT_INVENTORY_FAILED",
    SYNC_PRODUCT_INVENTORY_BY_CONDITION_START: "SYNC_PRODUCT_INVENTORY_BY_CONDITION_START",
    SYNC_PRODUCT_INVENTORY_BY_CONDITION_BREAK: "SYNC_PRODUCT_INVENTORY_BY_CONDITION_BREAK",
    SYNC_PRODUCT_INVENTORY_BY_CONDITION_SUCCESS: "SYNC_PRODUCT_INVENTORY_BY_CONDITION_SUCCESS",
    SYNC_PRODUCT_INVENTORY_BY_CONDITION_FAILED: "SYNC_PRODUCT_INVENTORY_BY_CONDITION_FAILED",
    GET_PRODUCT_MONTH_INVENTORY_START: "GET_PRODUCT_MONTH_INVENTORY_START",
    GET_PRODUCT_MONTH_INVENTORY_BREAK: "GET_PRODUCT_MONTH_INVENTORY_BREAK",
    GET_PRODUCT_MONTH_INVENTORY_SUCCESS: "GET_PRODUCT_MONTH_INVENTORY_SUCCESS",
    GET_PRODUCT_MONTH_INVENTORY_FAILED: "GET_PRODUCT_MONTH_INVENTORY_FAILED",
    GET_PRODUCT_DATE_INVENTORY_START: "GET_PRODUCT_DATE_INVENTORY_START",
    GET_PRODUCT_DATE_INVENTORY_BREAK: "GET_PRODUCT_DATE_INVENTORY_BREAK",
    GET_PRODUCT_DATE_INVENTORY_SUCCESS: "GET_PRODUCT_DATE_INVENTORY_SUCCESS",
    GET_PRODUCT_DATE_INVENTORY_FAILED: "GET_PRODUCT_DATE_INVENTORY_FAILED",
    GET_PRODUCT_INVENTORY_CALENDAR_START: "GET_PRODUCT_INVENTORY_CALENDAR_START",
    GET_PRODUCT_INVENTORY_CALENDAR_BREAK: "GET_PRODUCT_INVENTORY_CALENDAR_BREAK",
    GET_PRODUCT_INVENTORY_CALENDAR_SUCCESS: "GET_PRODUCT_INVENTORY_CALENDAR_SUCCESS",
    GET_PRODUCT_INVENTORY_CALENDAR_FAILED: "GET_PRODUCT_INVENTORY_CALENDAR_FAILED",
    DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
    DELETE_PRODUCT_BREAK: "DELETE_PRODUCT_BREAK",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILED: "DELETE_PRODUCT_FAILED",
    DUPLICATE_PRODUCT_START: "DUPLICATE_PRODUCT_START",
    DUPLICATE_PRODUCT_BREAK: "DUPLICATE_PRODUCT_BREAK",
    DUPLICATE_PRODUCT_SUCCESS: "DUPLICATE_PRODUCT_SUCCESS",
    DUPLICATE_PRODUCT_FAILED: "DUPLICATE_PRODUCT_FAILED",
    GET_PRODUCT_LANGUAGE_ALL_START: "GET_PRODUCT_LANGUAGE_ALL_START",
    GET_PRODUCT_LANGUAGE_ALL_BREAK: "GET_PRODUCT_LANGUAGE_ALL_BREAK",
    GET_PRODUCT_LANGUAGE_ALL_SUCCESS: "GET_PRODUCT_LANGUAGE_ALL_SUCCESS",
    GET_PRODUCT_LANGUAGE_ALL_FAILED: "GET_PRODUCT_LANGUAGE_ALL_FAILED",
    GET_PRODUCT_LANGUAGE_DETAIL_START: "GET_PRODUCT_LANGUAGE_DETAIL_START",
    GET_PRODUCT_LANGUAGE_DETAIL_BREAK: "GET_PRODUCT_LANGUAGE_DETAIL_BREAK",
    GET_PRODUCT_LANGUAGE_DETAIL_SUCCESS: "GET_PRODUCT_LANGUAGE_DETAIL_SUCCESS",
    GET_PRODUCT_LANGUAGE_DETAIL_FAILED: "GET_PRODUCT_LANGUAGE_DETAIL_FAILED",
    CREATE_PRODUCT_LANGUAGE_START: "CREATE_PRODUCT_LANGUAGE_START",
    CREATE_PRODUCT_LANGUAGE_BREAK: "CREATE_PRODUCT_LANGUAGE_BREAK",
    CREATE_PRODUCT_LANGUAGE_SUCCESS: "CREATE_PRODUCT_LANGUAGE_SUCCESS",
    CREATE_PRODUCT_LANGUAGE_FAILED: "CREATE_PRODUCT_LANGUAGE_FAILED",
    UPDATE_PRODUCT_LANGUAGE_START: "UPDATE_PRODUCT_LANGUAGE_START",
    UPDATE_PRODUCT_LANGUAGE_BREAK: "UPDATE_PRODUCT_LANGUAGE_BREAK",
    UPDATE_PRODUCT_LANGUAGE_SUCCESS: "UPDATE_PRODUCT_LANGUAGE_SUCCESS",
    UPDATE_PRODUCT_LANGUAGE_FAILED: "UPDATE_PRODUCT_LANGUAGE_FAILED",
    DELETE_PRODUCT_LANGUAGE_START: "DELETE_PRODUCT_LANGUAGE_START",
    DELETE_PRODUCT_LANGUAGE_BREAK: "DELETE_PRODUCT_LANGUAGE_BREAK",
    DELETE_PRODUCT_LANGUAGE_SUCCESS: "DELETE_PRODUCT_LANGUAGE_SUCCESS",
    DELETE_PRODUCT_LANGUAGE_FAILED: "DELETE_PRODUCT_LANGUAGE_FAILED",
};

export const orderActions = {
    CREATE_ORDER_START: "CREATE_ORDER_START",
    CREATE_ORDER_BREAK: "CREATE_ORDER_BREAK",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILED: "CREATE_ORDER_FAILED",
    UPDATE_ORDER_START: "UPDATE_ORDER_START",
    UPDATE_ORDER_BREAK: "UPDATE_ORDER_BREAK",
    UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
    UPDATE_ORDER_FAILED: "UPDATE_ORDER_FAILED",
    RECEIVE_ORDER_START: "RECEIVE_ORDER_START",
    RECEIVE_ORDER_BREAK: "RECEIVE_ORDER_BREAK",
    RECEIVE_ORDER_SUCCESS: "RECEIVE_ORDER_SUCCESS",
    RECEIVE_ORDER_FAILED: "RECEIVE_ORDER_FAILED",
    CONFIRM_ORDER_START: "CONFIRM_ORDER_START",
    CONFIRM_ORDER_BREAK: "CONFIRM_ORDER_BREAK",
    CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
    CONFIRM_ORDER_FAILED: "CONFIRM_ORDER_FAILED",
    CANCEL_ORDER_START: "CANCEL_ORDER_START",
    CANCEL_ORDER_BREAK: "CANCEL_ORDER_BREAK",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILED: "CANCEL_ORDER_FAILED",
    COMPLETE_ORDER_START: "COMPLETE_ORDER_START",
    COMPLETE_ORDER_BREAK: "COMPLETE_ORDER_BREAK",
    COMPLETE_ORDER_SUCCESS: "COMPLETE_ORDER_SUCCESS",
    COMPLETE_ORDER_FAILED: "COMPLETE_ORDER_FAILED",
    UPDATE_ORDER_COMBINE_START: "UPDATE_ORDER_COMBINE_START",
    UPDATE_ORDER_COMBINE_BREAK: "UPDATE_ORDER_COMBINE_BREAK",
    UPDATE_ORDER_COMBINE_SUCCESS: "UPDATE_ORDER_COMBINE_SUCCESS",
    UPDATE_ORDER_COMBINE_FAILED: "UPDATE_ORDER_COMBINE_FAILED",
    UPDATE_ORDER_LOCATION_START: "UPDATE_ORDER_LOCATION_START",
    UPDATE_ORDER_LOCATION_BREAK: "UPDATE_ORDER_LOCATION_BREAK",
    UPDATE_ORDER_LOCATION_SUCCESS: "UPDATE_ORDER_LOCATION_SUCCESS",
    UPDATE_ORDER_LOCATION_FAILED: "UPDATE_ORDER_LOCATION_FAILED",
    UPDATE_ORDER_CONFIRM_NOTE_START: "UPDATE_ORDER_CONFIRM_NOTE_START",
    UPDATE_ORDER_CONFIRM_NOTE_BREAK: "UPDATE_ORDER_CONFIRM_NOTE_BREAK",
    UPDATE_ORDER_CONFIRM_NOTE_SUCCESS: "UPDATE_ORDER_CONFIRM_NOTE_SUCCESS",
    UPDATE_ORDER_CONFIRM_NOTE_FAILED: "UPDATE_ORDER_CONFIRM_NOTE_FAILED",
    UPDATE_ORDER_OPERATOR_NOTE_START: "UPDATE_ORDER_OPERATOR_NOTE_START",
    UPDATE_ORDER_OPERATOR_NOTE_BREAK: "UPDATE_ORDER_OPERATOR_NOTE_BREAK",
    UPDATE_ORDER_OPERATOR_NOTE_SUCCESS: "UPDATE_ORDER_OPERATOR_NOTE_SUCCESS",
    UPDATE_ORDER_OPERATOR_NOTE_FAILED: "UPDATE_ORDER_OPERATOR_NOTE_FAILED",
    CHECK_ORDER_SEAT_START: "CHECK_ORDER_SEAT_START",
    CHECK_ORDER_SEAT_BREAK: "CHECK_ORDER_SEAT_BREAK",
    CHECK_ORDER_SEAT_SUCCESS: "CHECK_ORDER_SEAT_SUCCESS",
    CHECK_ORDER_SEAT_FAILED: "CHECK_ORDER_SEAT_FAILED",
    GET_ORDER_DETAIL_START: "GET_ORDER_DETAIL_START",
    GET_ORDER_DETAIL_BREAK: "GET_ORDER_DETAIL_BREAK",
    GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
    GET_ORDER_DETAIL_FAILED: "GET_ORDER_DETAIL_FAILED",
    GET_ORDER_DETAIL_PAGE_START: "GET_ORDER_DETAIL_PAGE_START",
    GET_ORDER_DETAIL_PAGE_BREAK: "GET_ORDER_DETAIL_PAGE_BREAK",
    GET_ORDER_DETAIL_PAGE_SUCCESS: "GET_ORDER_DETAIL_PAGE_SUCCESS",
    GET_ORDER_DETAIL_PAGE_FAILED: "GET_ORDER_DETAIL_PAGE_FAILED",
    GET_ORDER_PAGE_START: "GET_ORDER_PAGE_START",
    GET_ORDER_PAGE_BREAK: "GET_ORDER_PAGE_BREAK",
    GET_ORDER_PAGE_SUCCESS: "GET_ORDER_PAGE_SUCCESS",
    GET_ORDER_PAGE_FAILED: "GET_ORDER_PAGE_FAILED",
    EXPORT_ORDER_START: "EXPORT_ORDER_START",
    EXPORT_ORDER_BREAK: "EXPORT_ORDER_BREAK",
    EXPORT_ORDER_SUCCESS: "EXPORT_ORDER_SUCCESS",
    EXPORT_ORDER_FAILED: "EXPORT_ORDER_FAILED",
    CREATE_ORDER_FEE_START: "CREATE_ORDER_FEE_START",
    CREATE_ORDER_FEE_BREAK: "CREATE_ORDER_FEE_BREAK",
    CREATE_ORDER_FEE_SUCCESS: "CREATE_ORDER_FEE_SUCCESS",
    CREATE_ORDER_FEE_FAILED: "CREATE_ORDER_FEE_FAILED",
    CANCEL_ORDER_FEE_START: "CANCEL_ORDER_FEE_START",
    CANCEL_ORDER_FEE_BREAK: "CANCEL_ORDER_FEE_BREAK",
    CANCEL_ORDER_FEE_SUCCESS: "CANCEL_ORDER_FEE_SUCCESS",
    CANCEL_ORDER_FEE_FAILED: "CANCEL_ORDER_FEE_FAILED",
    GET_ORDER_FEE_DETAIL_START: "GET_ORDER_FEE_DETAIL_START",
    GET_ORDER_FEE_DETAIL_BREAK: "GET_ORDER_FEE_DETAIL_BREAK",
    GET_ORDER_FEE_DETAIL_SUCCESS: "GET_ORDER_FEE_DETAIL_SUCCESS",
    GET_ORDER_FEE_DETAIL_FAILED: "GET_ORDER_FEE_DETAIL_FAILED",
    GET_ORDER_FEE_DETAIL_PAGE_START: "GET_ORDER_FEE_DETAIL_PAGE_START",
    GET_ORDER_FEE_DETAIL_PAGE_BREAK: "GET_ORDER_FEE_DETAIL_PAGE_BREAK",
    GET_ORDER_FEE_DETAIL_PAGE_SUCCESS: "GET_ORDER_FEE_DETAIL_PAGE_SUCCESS",
    GET_ORDER_FEE_DETAIL_PAGE_FAILED: "GET_ORDER_FEE_DETAIL_PAGE_FAILED",
    SEND_ORDER_EMAIL_START: "SEND_ORDER_EMAIL_START",
    SEND_ORDER_EMAIL_BREAK: "SEND_ORDER_EMAIL_BREAK",
    SEND_ORDER_EMAIL_SUCCESS: "SEND_ORDER_EMAIL_SUCCESS",
    SEND_ORDER_EMAIL_FAILED: "SEND_ORDER_EMAIL_FAILED",
    GET_ORDER_LOG_START: "GET_ORDER_LOG_START",
    GET_ORDER_LOG_BREAK: "GET_ORDER_LOG_BREAK",
    GET_ORDER_LOG_SUCCESS: "GET_ORDER_LOG_SUCCESS",
    GET_ORDER_LOG_FAILED: "GET_ORDER_LOG_FAILED",
    GET_ORDER_NOTICE_DETAIL_START: "GET_ORDER_NOTICE_DETAIL_START",
    GET_ORDER_NOTICE_DETAIL_BREAK: "GET_ORDER_NOTICE_DETAIL_BREAK",
    GET_ORDER_NOTICE_DETAIL_SUCCESS: "GET_ORDER_NOTICE_DETAIL_SUCCESS",
    GET_ORDER_NOTICE_DETAIL_FAILED: "GET_ORDER_NOTICE_DETAIL_FAILED",
};

export const resourceActions = {
    CREATE_HOTEL_START: "CREATE_HOTEL_START",
    CREATE_HOTEL_BREAK: "CREATE_HOTEL_BREAK",
    CREATE_HOTEL_SUCCESS: "CREATE_HOTEL_SUCCESS",
    CREATE_HOTEL_FAILED: "CREATE_HOTEL_FAILED",
    UPDATE_HOTEL_START: "UPDATE_HOTEL_START",
    UPDATE_HOTEL_BREAK: "UPDATE_HOTEL_BREAK",
    UPDATE_HOTEL_SUCCESS: "UPDATE_HOTEL_SUCCESS",
    UPDATE_HOTEL_FAILED: "UPDATE_HOTEL_FAILED",
    DELETE_HOTEL_START: "DELETE_HOTEL_START",
    DELETE_HOTEL_BREAK: "DELETE_HOTEL_BREAK",
    DELETE_HOTEL_SUCCESS: "DELETE_HOTEL_SUCCESS",
    DELETE_HOTEL_FAILED: "DELETE_HOTEL_FAILED",
    UPLOAD_HOTEL_PHOTO_START: "UPLOAD_HOTEL_PHOTO_START",
    UPLOAD_HOTEL_PHOTO_BREAK: "UPLOAD_HOTEL_PHOTO_BREAK",
    UPLOAD_HOTEL_PHOTO_SUCCESS: "UPLOAD_HOTEL_PHOTO_SUCCESS",
    UPLOAD_HOTEL_PHOTO_FAILED: "UPLOAD_HOTEL_PHOTO_FAILED",
    GET_HOTEL_DETAIL_START: "GET_HOTEL_DETAIL_START",
    GET_HOTEL_DETAIL_BREAK: "GET_HOTEL_DETAIL_BREAK",
    GET_HOTEL_DETAIL_SUCCESS: "GET_HOTEL_DETAIL_SUCCESS",
    GET_HOTEL_DETAIL_FAILED: "GET_HOTEL_DETAIL_FAILED",
    GET_HOTEL_PAGE_START: "GET_HOTEL_PAGE_START",
    GET_HOTEL_PAGE_BREAK: "GET_HOTEL_PAGE_BREAK",
    GET_HOTEL_PAGE_SUCCESS: "GET_HOTEL_PAGE_SUCCESS",
    GET_HOTEL_PAGE_FAILED: "GET_HOTEL_PAGE_FAILED",
};

export const questionActions = {
    GET_QUESTION_PAGE_START: "GET_QUESTION_PAGE_START",
    GET_QUESTION_PAGE_BREAK: "GET_QUESTION_PAGE_BREAK",
    GET_QUESTION_PAGE_SUCCESS: "GET_QUESTION_PAGE_SUCCESS",
    GET_QUESTION_PAGE_FAILED: "GET_QUESTION_PAGE_FAILED",
    GET_QUESTION_DETAIL_START: "GET_QUESTION_DETAIL_START",
    GET_QUESTION_DETAIL_BREAK: "GET_QUESTION_DETAIL_BREAK",
    GET_QUESTION_DETAIL_SUCCESS: "GET_QUESTION_DETAIL_SUCCESS",
    GET_QUESTION_DETAIL_FAILED: "GET_QUESTION_DETAIL_FAILED",
    READ_QUESTION_START: "READ_QUESTION_START",
    READ_QUESTION_BREAK: "READ_QUESTION_BREAK",
    READ_QUESTION_SUCCESS: "READ_QUESTION_SUCCESS",
    READ_QUESTION_FAILED: "READ_QUESTION_FAILED",
    REPLY_QUESTION_START: "REPLY_QUESTION_START",
    REPLY_QUESTION_BREAK: "REPLY_QUESTION_BREAK",
    REPLY_QUESTION_SUCCESS: "REPLY_QUESTION_SUCCESS",
    REPLY_QUESTION_FAILED: "REPLY_QUESTION_FAILED",
    DELETE_QUESTION_START: "DELETE_QUESTION_START",
    DELETE_QUESTION_BREAK: "DELETE_QUESTION_BREAK",
    DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
    DELETE_QUESTION_FAILED: "DELETE_QUESTION_FAILED",
};

export const helpActions = {
    GET_HELP_PAGE_START: "GET_HELP_PAGE_START",
    GET_HELP_PAGE_BREAK: "GET_HELP_PAGE_BREAK",
    GET_HELP_PAGE_SUCCESS: "GET_HELP_PAGE_SUCCESS",
    GET_HELP_PAGE_FAILED: "GET_HELP_PAGE_FAILED",
    GET_HELP_DETAIL_START: "GET_HELP_DETAIL_START",
    GET_HELP_DETAIL_BREAK: "GET_HELP_DETAIL_BREAK",
    GET_HELP_DETAIL_SUCCESS: "GET_HELP_DETAIL_SUCCESS",
    GET_HELP_DETAIL_FAILED: "GET_HELP_DETAIL_FAILED",
};

export const supplierActions = {
    UPDATE_SUPPLIER_START: "UPDATE_SUPPLIER_START",
    UPDATE_SUPPLIER_BREAK: "UPDATE_SUPPLIER_BREAK",
    UPDATE_SUPPLIER_SUCCESS: "UPDATE_SUPPLIER_SUCCESS",
    UPDATE_SUPPLIER_FAILED: "UPDATE_SUPPLIER_FAILED",
    GET_SUPPLIER_DETAIL_START: "GET_SUPPLIER_DETAIL_START",
    GET_SUPPLIER_DETAIL_BREAK: "GET_SUPPLIER_DETAIL_BREAK",
    GET_SUPPLIER_DETAIL_SUCCESS: "GET_SUPPLIER_DETAIL_SUCCESS",
    GET_SUPPLIER_DETAIL_FAILED: "GET_SUPPLIER_DETAIL_FAILED",
    GET_SUPPLIER_PAGE_START: "GET_SUPPLIER_PAGE_START",
    GET_SUPPLIER_PAGE_BREAK: "GET_SUPPLIER_PAGE_BREAK",
    GET_SUPPLIER_PAGE_SUCCESS: "GET_SUPPLIER_PAGE_SUCCESS",
    GET_SUPPLIER_PAGE_FAILED: "GET_SUPPLIER_PAGE_FAILED",
    GET_PRODUCT_PAGE_START: "GET_PRODUCT_PAGE_START",
    GET_PRODUCT_PAGE_BREAK: "GET_PRODUCT_PAGE_BREAK",
    GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
    GET_PRODUCT_PAGE_FAILED: "GET_PRODUCT_PAGE_FAILED",
    GET_PRODUCT_DETAIL_START: "GET_PRODUCT_DETAIL_START",
    GET_PRODUCT_DETAIL_BREAK: "GET_PRODUCT_DETAIL_BREAK",
    GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
    GET_PRODUCT_DETAIL_FAILED: "GET_PRODUCT_DETAIL_FAILED",
    GET_PRODUCT_MONTH_INVENTORY_START: "GET_PRODUCT_MONTH_INVENTORY_START",
    GET_PRODUCT_MONTH_INVENTORY_BREAK: "GET_PRODUCT_MONTH_INVENTORY_BREAK",
    GET_PRODUCT_MONTH_INVENTORY_SUCCESS: "GET_PRODUCT_MONTH_INVENTORY_SUCCESS",
    GET_PRODUCT_MONTH_INVENTORY_FAILED: "GET_PRODUCT_MONTH_INVENTORY_FAILED",
    GET_PRODUCT_DATE_INVENTORY_START: "GET_PRODUCT_DATE_INVENTORY_START",
    GET_PRODUCT_DATE_INVENTORY_BREAK: "GET_PRODUCT_DATE_INVENTORY_BREAK",
    GET_PRODUCT_DATE_INVENTORY_SUCCESS: "GET_PRODUCT_DATE_INVENTORY_SUCCESS",
    GET_PRODUCT_DATE_INVENTORY_FAILED: "GET_PRODUCT_DATE_INVENTORY_FAILED",
    CREATE_ORDER_START: "CREATE_ORDER_START",
    CREATE_ORDER_BREAK: "CREATE_ORDER_BREAK",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILED: "CREATE_ORDER_FAILED",
    UPDATE_ORDER_START: "UPDATE_ORDER_START",
    UPDATE_ORDER_BREAK: "UPDATE_ORDER_BREAK",
    UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
    UPDATE_ORDER_FAILED: "UPDATE_ORDER_FAILED",
    CANCEL_ORDER_START: "CANCEL_ORDER_START",
    CANCEL_ORDER_BREAK: "CANCEL_ORDER_BREAK",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILED: "CANCEL_ORDER_FAILED",
    CHECK_ORDER_SEAT_START: "CHECK_ORDER_SEAT_START",
    CHECK_ORDER_SEAT_BREAK: "CHECK_ORDER_SEAT_BREAK",
    CHECK_ORDER_SEAT_SUCCESS: "CHECK_ORDER_SEAT_SUCCESS",
    CHECK_ORDER_SEAT_FAILED: "CHECK_ORDER_SEAT_FAILED",
    GET_ORDER_DETAIL_START: "GET_ORDER_DETAIL_START",
    GET_ORDER_DETAIL_BREAK: "GET_ORDER_DETAIL_BREAK",
    GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
    GET_ORDER_DETAIL_FAILED: "GET_ORDER_DETAIL_FAILED",
    GET_ORDER_DETAIL_PAGE_START: "GET_ORDER_DETAIL_PAGE_START",
    GET_ORDER_DETAIL_PAGE_BREAK: "GET_ORDER_DETAIL_PAGE_BREAK",
    GET_ORDER_DETAIL_PAGE_SUCCESS: "GET_ORDER_DETAIL_PAGE_SUCCESS",
    GET_ORDER_DETAIL_PAGE_FAILED: "GET_ORDER_DETAIL_PAGE_FAILED",
    GET_ORDER_PAGE_START: "GET_ORDER_PAGE_START",
    GET_ORDER_PAGE_BREAK: "GET_ORDER_PAGE_BREAK",
    GET_ORDER_PAGE_SUCCESS: "GET_ORDER_PAGE_SUCCESS",
    GET_ORDER_PAGE_FAILED: "GET_ORDER_PAGE_FAILED",
    GET_ORDER_FEE_DETAIL_START: "GET_ORDER_FEE_DETAIL_START",
    GET_ORDER_FEE_DETAIL_BREAK: "GET_ORDER_FEE_DETAIL_BREAK",
    GET_ORDER_FEE_DETAIL_SUCCESS: "GET_ORDER_FEE_DETAIL_SUCCESS",
    GET_ORDER_FEE_DETAIL_FAILED: "GET_ORDER_FEE_DETAIL_FAILED",
    GET_ORDER_FEE_DETAIL_PAGE_START: "GET_ORDER_FEE_DETAIL_PAGE_START",
    GET_ORDER_FEE_DETAIL_PAGE_BREAK: "GET_ORDER_FEE_DETAIL_PAGE_BREAK",
    GET_ORDER_FEE_DETAIL_PAGE_SUCCESS: "GET_ORDER_FEE_DETAIL_PAGE_SUCCESS",
    GET_ORDER_FEE_DETAIL_PAGE_FAILED: "GET_ORDER_FEE_DETAIL_PAGE_FAILED",
    GET_MESSAGE_DETAIL_START: "GET_MESSAGE_DETAIL_START",
    GET_MESSAGE_DETAIL_BREAK: "GET_MESSAGE_DETAIL_BREAK",
    GET_MESSAGE_DETAIL_SUCCESS: "GET_MESSAGE_DETAIL_SUCCESS",
    GET_MESSAGE_DETAIL_FAILED: "GET_MESSAGE_DETAIL_FAILED",
    GET_MESSAGE_PAGE_START: "GET_MESSAGE_PAGE_START",
    GET_MESSAGE_PAGE_BREAK: "GET_MESSAGE_PAGE_BREAK",
    GET_MESSAGE_PAGE_SUCCESS: "GET_MESSAGE_PAGE_SUCCESS",
    GET_MESSAGE_PAGE_FAILED: "GET_MESSAGE_PAGE_FAILED",
};

export const financeActions = {
    UPDATE_PAYMENT_START: "UPDATE_PAYMENT_START",
    UPDATE_PAYMENT_BREAK: "UPDATE_PAYMENT_BREAK",
    UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
    UPDATE_PAYMENT_FAILED: "UPDATE_PAYMENT_FAILED",
    GET_ALL_CHANNEL_ACCOUNT_START: "GET_ALL_CHANNEL_ACCOUNT_START",
    GET_ALL_CHANNEL_ACCOUNT_BREAK: "GET_ALL_CHANNEL_ACCOUNT_BREAK",
    GET_ALL_CHANNEL_ACCOUNT_SUCCESS: "GET_ALL_CHANNEL_ACCOUNT_SUCCESS",
    GET_ALL_CHANNEL_ACCOUNT_FAILED: "GET_ALL_CHANNEL_ACCOUNT_FAILED",
    GET_INVOICE_DETAIL_START: "GET_INVOICE_DETAIL_START",
    GET_INVOICE_DETAIL_BREAK: "GET_INVOICE_DETAIL_BREAK",
    GET_INVOICE_DETAIL_SUCCESS: "GET_INVOICE_DETAIL_SUCCESS",
    GET_INVOICE_DETAIL_FAILED: "GET_INVOICE_DETAIL_FAILED",
    GET_INVOICE_PAGE_START: "GET_INVOICE_PAGE_START",
    GET_INVOICE_PAGE_BREAK: "GET_INVOICE_PAGE_BREAK",
    GET_INVOICE_PAGE_SUCCESS: "GET_INVOICE_PAGE_SUCCESS",
    GET_INVOICE_PAGE_FAILED: "GET_INVOICE_PAGE_FAILED",
    BILL_INVOICE_START: "BILL_INVOICE_START",
    BILL_INVOICE_BREAK: "BILL_INVOICE_BREAK",
    BILL_INVOICE_SUCCESS: "BILL_INVOICE_SUCCESS",
    BILL_INVOICE_FAILED: "BILL_INVOICE_FAILED",
    GET_BILL_DETAIL_START: "GET_BILL_DETAIL_START",
    GET_BILL_DETAIL_BREAK: "GET_BILL_DETAIL_BREAK",
    GET_BILL_DETAIL_SUCCESS: "GET_BILL_DETAIL_SUCCESS",
    GET_BILL_DETAIL_FAILED: "GET_BILL_DETAIL_FAILED",
    GET_BILL_PAGE_START: "GET_BILL_PAGE_START",
    GET_BILL_PAGE_BREAK: "GET_BILL_PAGE_BREAK",
    GET_BILL_PAGE_SUCCESS: "GET_BILL_PAGE_SUCCESS",
    GET_BILL_PAGE_FAILED: "GET_BILL_PAGE_FAILED",
    CONFIRM_BILL_START: "CONFIRM_BILL_START",
    CONFIRM_BILL_BREAK: "CONFIRM_BILL_BREAK",
    CONFIRM_BILL_SUCCESS: "CONFIRM_BILL_SUCCESS",
    CONFIRM_BILL_FAILED: "CONFIRM_BILL_FAILED",
    CANCEL_BILL_START: "CANCEL_BILL_START",
    CANCEL_BILL_BREAK: "CANCEL_BILL_BREAK",
    CANCEL_BILL_SUCCESS: "CANCEL_BILL_SUCCESS",
    CANCEL_BILL_FAILED: "CANCEL_BILL_FAILED",
    SEND_BILL_START: "SEND_BILL_START",
    SEND_BILL_BREAK: "SEND_BILL_BREAK",
    SEND_BILL_SUCCESS: "SEND_BILL_SUCCESS",
    SEND_BILL_FAILED: "SEND_BILL_FAILED",
};

export const channelActions = {
    GET_CHANNEL_DETAIL_START: "GET_CHANNEL_DETAIL_START",
    GET_CHANNEL_DETAIL_BREAK: "GET_CHANNEL_DETAIL_BREAK",
    GET_CHANNEL_DETAIL_SUCCESS: "GET_CHANNEL_DETAIL_SUCCESS",
    GET_CHANNEL_DETAIL_FAILED: "GET_CHANNEL_DETAIL_FAILED",
    GET_CHANNEL_PAGE_START: "GET_CHANNEL_PAGE_START",
    GET_CHANNEL_PAGE_BREAK: "GET_CHANNEL_PAGE_BREAK",
    GET_CHANNEL_PAGE_SUCCESS: "GET_CHANNEL_PAGE_SUCCESS",
    GET_CHANNEL_PAGE_FAILED: "GET_CHANNEL_PAGE_FAILED",
    GET_CHANNEL_PROMOTION_DETAIL_START: "GET_CHANNEL_PROMOTION_DETAIL_START",
    GET_CHANNEL_PROMOTION_DETAIL_BREAK: "GET_CHANNEL_PROMOTION_DETAIL_BREAK",
    GET_CHANNEL_PROMOTION_DETAIL_SUCCESS: "GET_CHANNEL_PROMOTION_DETAIL_SUCCESS",
    GET_CHANNEL_PROMOTION_DETAIL_FAILED: "GET_CHANNEL_PROMOTION_DETAIL_FAILED",
    GET_CHANNEL_PROMOTION_PAGE_START: "GET_CHANNEL_PROMOTION_PAGE_START",
    GET_CHANNEL_PROMOTION_PAGE_BREAK: "GET_CHANNEL_PROMOTION_PAGE_BREAK",
    GET_CHANNEL_PROMOTION_PAGE_SUCCESS: "GET_CHANNEL_PROMOTION_PAGE_SUCCESS",
    GET_CHANNEL_PROMOTION_PAGE_FAILED: "GET_CHANNEL_PROMOTION_PAGE_FAILED",
    JOIN_CHANNEL_PROMOTION_START: "JOIN_CHANNEL_PROMOTION_START",
    JOIN_CHANNEL_PROMOTION_BREAK: "JOIN_CHANNEL_PROMOTION_BREAK",
    JOIN_CHANNEL_PROMOTION_SUCCESS: "JOIN_CHANNEL_PROMOTION_SUCCESS",
    JOIN_CHANNEL_PROMOTION_FAILED: "JOIN_CHANNEL_PROMOTION_FAILED",
    GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_START: "GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_START",
    GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_BREAK: "GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_BREAK",
    GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_SUCCESS: "GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_SUCCESS",
    GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_FAILED: "GET_CHANNEL_PROMOTION_PRODUCT_DETAIL_FAILED",
    GET_CHANNEL_PROMOTION_PRODUCT_PAGE_START: "GET_CHANNEL_PROMOTION_PRODUCT_PAGE_START",
    GET_CHANNEL_PROMOTION_PRODUCT_PAGE_BREAK: "GET_CHANNEL_PROMOTION_PRODUCT_PAGE_BREAK",
    GET_CHANNEL_PROMOTION_PRODUCT_PAGE_SUCCESS: "GET_CHANNEL_PROMOTION_PRODUCT_PAGE_SUCCESS",
    GET_CHANNEL_PROMOTION_PRODUCT_PAGE_FAILED: "GET_CHANNEL_PROMOTION_PRODUCT_PAGE_FAILED",
    DELETE_CHANNEL_PROMOTION_PRODUCT_START: "DELETE_CHANNEL_PROMOTION_PRODUCT_START",
    DELETE_CHANNEL_PROMOTION_PRODUCT_BREAK: "DELETE_CHANNEL_PROMOTION_PRODUCT_BREAK",
    DELETE_CHANNEL_PROMOTION_PRODUCT_SUCCESS: "DELETE_CHANNEL_PROMOTION_PRODUCT_SUCCESS",
    DELETE_CHANNEL_PROMOTION_PRODUCT_FAILED: "DELETE_CHANNEL_PROMOTION_PRODUCT_FAILED",
};

export const staticActions = {
    // ACTION_START: "ACTION_START",
    // ACTION_SUCCESS: "ACTION_SUCCESS",
    // ACTION_FAILED: "ACTION_FAILED",
    UPLOAD_IMAGE_START: "UPLOAD_IMAGE_START",
    UPLOAD_IMAGE_BREAK: "UPLOAD_IMAGE_BREAK",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",
    GET_ALL_TIMEZONE_START: "GET_ALL_TIMEZONE_START",
    GET_ALL_TIMEZONE_SUCCESS: "GET_ALL_TIMEZONE_SUCCESS",
    GET_ALL_TIMEZONE_FAILED: "GET_ALL_TIMEZONE_FAILED",
    GET_ALL_MODULE_START: "GET_ALL_MODULE_START",
    GET_ALL_MODULE_SUCCESS: "GET_ALL_MODULE_SUCCESS",
    GET_ALL_MODULE_FAILED: "GET_ALL_MODULE_FAILED",
    GET_ALL_CITY_START: "GET_ALL_CITY_START",
    GET_ALL_CITY_BREAK: "GET_ALL_CITY_BREAK",
    GET_ALL_CITY_SUCCESS: "GET_ALL_CITY_SUCCESS",
    GET_ALL_CITY_FAILED: "GET_ALL_CITY_FAILED",
    GET_HOME_STATICS_START: "GET_HOME_STATICS_START",
    GET_HOME_STATICS_BREAK: "GET_HOME_STATICS_BREAK",
    GET_HOME_STATICS_SUCCESS: "GET_HOME_STATICS_SUCCESS",
    GET_HOME_STATICS_FAILED: "GET_HOME_STATICS_FAILED",
    GET_NAV_STATICS_START: "GET_NAV_STATICS_START",
    GET_NAV_STATICS_BREAK: "GET_NAV_STATICS_BREAK",
    GET_NAV_STATICS_SUCCESS: "GET_NAV_STATICS_SUCCESS",
    GET_NAV_STATICS_FAILED: "GET_NAV_STATICS_FAILED",
};
