import { staticActions } from "../actions/actionTypes";
import { staticInitState } from "../model";

export const uploadImage = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.UPLOAD_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.UPLOAD_BREAK:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case staticActions.UPLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.UPLOAD_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const allTimezone = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.GET_ALL_TIMEZONE_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.GET_ALL_TIMEZONE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.GET_ALL_TIMEZONE_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const allModule = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.GET_ALL_MODULE_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.GET_ALL_MODULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.GET_ALL_MODULE_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const allCity = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.GET_ALL_CITY_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.GET_ALL_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.GET_ALL_CITY_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const homeStatics = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.GET_HOME_STATICS_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.GET_HOME_STATICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.GET_HOME_STATICS_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const navStatics = (state = staticInitState, action) => {
    switch (action.type) {
        case staticActions.GET_NAV_STATICS_START:
            return {
                ...staticInitState,
                isLoading: true,
                isSuccess: false,
            };
        case staticActions.GET_NAV_STATICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case staticActions.GET_NAV_STATICS_FAILED:
            return {
                ...staticInitState,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};