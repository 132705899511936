export const Config = {
    Version: "220915.1",
    // Server: "http://ti.rpdc.cc/", // DEV
    // Server: "http://travel.gmzsoft.com/",   // TEST
    Server: "https://tti.ropinsite.com/", // PROD
    // Client: "http://localhost:3000/",    // DEV
    // Client: "http://merchant.gmzsoft.com/", // TEST
    Client: "https://tr.ropin.net/", // PROD
    PassengerClient: "https://trip.ropin.net/",
    AppKey: "",
    RopinKey: "56ef97c5259a10b8f3906c5654f8dc03",
    defaultPageSize: 20,
};
