import { questionActions } from "../actions/actionTypes";
import { questionInitState, questionPageInitState } from "../model";

export const questionDetail = (state = questionInitState, action) => {
    switch (action.type) {
        case questionActions.GET_QUESTION_DETAIL_START:
            return {
                ...questionInitState,
                isLoading: true,
                isLogin: false,
                error: "",
            };
        case questionActions.GET_QUESTION_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: "PERMISSION DENIED",
            };
        case questionActions.GET_QUESTION_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLogin: true,
                data: action.data,
            };
        case questionActions.GET_QUESTION_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isLogin: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const questionPage = (state = questionPageInitState, action) => {
    switch (action.type) {
        case questionActions.GET_QUESTION_PAGE_START:
            return {
                ...questionPageInitState,
                isLoading: true,
                error: action.error,
            };
        case questionActions.GET_QUESTION_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case questionActions.GET_QUESTION_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case questionActions.GET_QUESTION_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const questionAction = (state = questionInitState, action) => {
    switch (action.type) {
        case questionActions.READ_QUESTION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case questionActions.READ_QUESTION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case questionActions.READ_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case questionActions.READ_QUESTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case questionActions.REPLY_QUESTION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case questionActions.REPLY_QUESTION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case questionActions.REPLY_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case questionActions.REPLY_QUESTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case questionActions.DELETE_QUESTION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case questionActions.DELETE_QUESTION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case questionActions.DELETE_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case questionActions.DELETE_QUESTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
