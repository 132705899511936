import { resourceActions } from "../actions/actionTypes";
import { hotelInitState, hotelPageInitState } from "../model";

export const hotelDetail = (state = hotelInitState, action) => {
    switch (action.type) {
        case resourceActions.GET_HOTEL_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case resourceActions.GET_HOTEL_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.GET_HOTEL_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case resourceActions.GET_HOTEL_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const hotelPage = (state = hotelPageInitState, action) => {
    switch (action.type) {
        case resourceActions.GET_HOTEL_PAGE_START:
            return {
                ...hotelPageInitState,
                isLoading: true,
                error: action.error,
            };
        case resourceActions.GET_HOTEL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.GET_HOTEL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case resourceActions.GET_HOTEL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const hotelAction = (state = hotelInitState, action) => {
    switch (action.type) {
        case resourceActions.CREATE_HOTEL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case resourceActions.CREATE_HOTEL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.CREATE_HOTEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case resourceActions.CREATE_HOTEL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case resourceActions.UPDATE_HOTEL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case resourceActions.UPDATE_HOTEL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.UPDATE_HOTEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case resourceActions.UPDATE_HOTEL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case resourceActions.DELETE_HOTEL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case resourceActions.DELETE_HOTEL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.DELETE_HOTEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case resourceActions.DELETE_HOTEL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case resourceActions.UPLOAD_HOTEL_PHOTO_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case resourceActions.UPLOAD_HOTEL_PHOTO_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case resourceActions.UPLOAD_HOTEL_PHOTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case resourceActions.UPLOAD_HOTEL_PHOTO_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
