import { Config } from "../../utilities/config";
import { merchantActions } from "./actionTypes";
import axios from "axios";
import moment from "moment";

export const updateMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/company",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_FAILED, error: error });
        });
};

export const updateMerchantLogo = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_LOGO_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_LOGO_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "setting/logo", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_LOGO_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_LOGO_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_LOGO_FAILED, error: error });
        });
};

export const updateMerchantSeal = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_SEAL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_SEAL_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "setting/seal", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_SEAL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_SEAL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_SEAL_FAILED, error: error });
        });
};

export const updateMerchantAvatar = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_AVATAR_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_AVATAR_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "setting/avatar", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_AVATAR_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_AVATAR_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_AVATAR_FAILED, error: error });
        });
};

export const updateMerchantBanner = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_BANNER_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_BANNER_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "setting/banner", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_BANNER_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_BANNER_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_BANNER_FAILED, error: error });
        });
};

export const updateMerchantCurrencyRate = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/currency",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_CURRENCY_RATE_FAILED, error: error });
        });
};

export const switchMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.SWITCH_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.SWITCH_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/merchant_switch",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.SWITCH_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.SWITCH_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.SWITCH_MERCHANT_FAILED, error: error });
        });
};

export const getMerchantAll = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_ALL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_ALL_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/merchant_all",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_ALL_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_ALL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_ALL_FAILED, error: error });
        });
};

export const merchantUserLogin = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.MERCHANT_USER_LOGIN_START });
    await axios
        .post(
            Config.Server + "auth/login",
            {
                userEmail: routeParams.userEmail,
                password: routeParams.password,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then(async (result) => {
            if (result.status === 200 && result.data.info.success) {
                localStorage.setItem("userCookie", result.data.data.userCookie);
                dispatch({ type: merchantActions.MERCHANT_USER_LOGIN_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.MERCHANT_USER_LOGIN_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.MERCHANT_USER_LOGIN_FAILED, error: error });
        });
};

export const merchantUserLogout = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.MERCHANT_USER_LOGOUT_START });
    localStorage.removeItem("userCookie");
    dispatch({ type: merchantActions.MERCHANT_USER_LOGOUT_SUCCESS });
};

export const merchantUserActivateValidate = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_VALIDATE_START });
    await axios
        .post(
            Config.Server + "auth/activate_validate",
            {
                userEmail: routeParams.userEmail,
                authCode: routeParams.authCode,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then(async (result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_VALIDATE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_VALIDATE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_VALIDATE_FAILED, error: error });
        });
};

export const merchantUserActivate = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_START });
    await axios
        .post(
            Config.Server + "auth/activate",
            {
                userEmail: routeParams.userEmail,
                newPassword: routeParams.newPassword,
                repeatPassword: routeParams.repeatPassword,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then(async (result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.MERCHANT_USER_ACTIVATE_FAILED, error: error });
        });
};

export const getCurrentMerchantUser = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_CURRENT_MERCHANT_USER_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_CURRENT_MERCHANT_USER_BREAK });
    }
    await axios
        .post(
            Config.Server + "auth/detail",
            {
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                result.data.data.userBirthDate = result.data.data.userBirthDate === null || result.data.data.userBirthDate === "" ? moment() : moment(result.data.data.userBirthDate);
                result.data.data.userCookie = userCookie;
                dispatch({ type: merchantActions.GET_CURRENT_MERCHANT_USER_SUCCESS, data: result.data.data });
            } else {
                localStorage.removeItem("userCookie");
                dispatch({ type: merchantActions.GET_CURRENT_MERCHANT_USER_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            localStorage.removeItem("userCookie");
            dispatch({ type: merchantActions.GET_CURRENT_MERCHANT_USER_FAILED, error: error });
        });
};

export const updateMerchantUserPhoto = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PHOTO_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PHOTO_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "auth/photo", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PHOTO_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PHOTO_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PHOTO_FAILED, error: error });
        });
};

export const updateMerchantUserProfile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PROFILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PROFILE_BREAK });
    }
    await axios
        .post(
            Config.Server + "auth/profile",
            {
                ...routeParams,
                userBirthDate: moment(routeParams.userBirthDate).format("YYYY-MM-DD"),
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PROFILE_SUCCESS });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PROFILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_PROFILE_FAILED, error: error });
        });
};

export const changeMerchantUserPassword = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CHANGE_MERCHANT_USER_PASSWORD_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CHANGE_MERCHANT_USER_PASSWORD_BREAK });
    }
    await axios
        .post(
            Config.Server + "auth/password",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CHANGE_MERCHANT_USER_PASSWORD_SUCCESS });
            } else {
                dispatch({ type: merchantActions.CHANGE_MERCHANT_USER_PASSWORD_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CHANGE_MERCHANT_USER_PASSWORD_FAILED, error: error });
        });
};

export const getMerchantUserPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_USER_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_USER_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/user_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_USER_PAGE_FAILED, error: error });
        });
};

export const getMerchantUserDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_USER_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_USER_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/user_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_USER_DETAIL_FAILED, error: error });
        });
};

export const createMerchantUser = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_USER_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_USER_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/user_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_USER_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_USER_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_USER_FAILED, error: error });
        });
};

export const updateMerchantUser = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/user_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_FAILED, error: error });
        });
};

export const deleteMerchantUser = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_USER_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_USER_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/user_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_USER_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_USER_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_USER_FAILED, error: error });
        });
};

export const getMerchantUserGroupPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/group_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_PAGE_FAILED, error: error });
        });
};

export const getMerchantUserGroupDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/group_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_USER_GROUP_DETAIL_FAILED, error: error });
        });
};

export const createMerchantUserGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_USER_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_USER_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/group_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_USER_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_USER_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_USER_GROUP_FAILED, error: error });
        });
};

export const updateMerchantUserGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/group_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_USER_GROUP_FAILED, error: error });
        });
};

export const deleteMerchantUserGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_USER_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_USER_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/group_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_USER_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_USER_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_USER_GROUP_FAILED, error: error });
        });
};

export const getMerchantGroupPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_GROUP_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_GROUP_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/group_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_GROUP_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_GROUP_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_GROUP_PAGE_FAILED, error: error });
        });
};

export const getMerchantGroupDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_GROUP_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_GROUP_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/group_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_GROUP_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_GROUP_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_GROUP_DETAIL_FAILED, error: error });
        });
};

export const createMerchantGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/group_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_GROUP_FAILED, error: error });
        });
};

export const updateMerchantGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/group_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_GROUP_FAILED, error: error });
        });
};

export const deleteMerchantGroup = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_GROUP_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_GROUP_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/group_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_GROUP_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_GROUP_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_GROUP_FAILED, error: error });
        });
};

export const getAgentMerchantPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_MERCHANT_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_MERCHANT_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_AGENT_MERCHANT_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_MERCHANT_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_MERCHANT_PAGE_FAILED, error: error });
        });
};

export const getAgentMerchantDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_MERCHANT_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_MERCHANT_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                result.data.data.agentExpiredAt = result.data.data.agentExpiredAt === null || result.data.data.agentExpiredAt === "" ? moment() : moment(result.data.data.agentExpiredAt);
                dispatch({ type: merchantActions.GET_AGENT_MERCHANT_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_MERCHANT_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_MERCHANT_DETAIL_FAILED, error: error });
        });
};

export const createAgentMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_AGENT_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_AGENT_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_AGENT_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_AGENT_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_AGENT_MERCHANT_FAILED, error: error });
        });
};

export const updateAgentMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_AGENT_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_AGENT_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_AGENT_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_AGENT_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_AGENT_MERCHANT_FAILED, error: error });
        });
};

export const terminateAgentMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.TERMINATE_AGENT_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.TERMINATE_AGENT_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/terminate",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.TERMINATE_AGENT_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.TERMINATE_AGENT_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.TERMINATE_AGENT_MERCHANT_FAILED, error: error });
        });
};

export const sendAuthAgentMerchant = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.SEND_AUTH_AGENT_MERCHANT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.SEND_AUTH_AGENT_MERCHANT_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/send_auth",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.SEND_AUTH_AGENT_MERCHANT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.SEND_AUTH_AGENT_MERCHANT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.SEND_AUTH_AGENT_MERCHANT_FAILED, error: error });
        });
};

export const getMerchantAccountPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/account_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_PAGE_FAILED, error: error });
        });
};

export const getMerchantAccountDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/account_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_ACCOUNT_DETAIL_FAILED, error: error });
        });
};

export const createMerchantAccount = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_ACCOUNT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_ACCOUNT_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/account_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_ACCOUNT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_ACCOUNT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_ACCOUNT_FAILED, error: error });
        });
};

export const updateMerchantAccount = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_ACCOUNT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_ACCOUNT_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/account_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_ACCOUNT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_ACCOUNT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_ACCOUNT_FAILED, error: error });
        });
};

export const deleteMerchantAccount = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_ACCOUNT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_ACCOUNT_BREAK });
    }
    await axios
        .post(
            Config.Server + "finance/account_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_ACCOUNT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_ACCOUNT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_ACCOUNT_FAILED, error: error });
        });
};

export const getMerchantLogPage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_LOG_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_LOG_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/log_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_LOG_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_LOG_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_LOG_PAGE_FAILED, error: error });
        });
};

export const getMerchantMessagePage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/message_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_PAGE_FAILED, error: error });
        });
};

export const getMerchantMessageDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/message_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_MESSAGE_DETAIL_FAILED, error: error });
        });
};

export const uploadMerchantMessageAttachment = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "agent/message_attachment", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPLOAD_MERCHANT_MESSAGE_ATTACHMENT_FAILED, error: error });
        });
};

export const createMerchantMessage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_MESSAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_MESSAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/message_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_MESSAGE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_MESSAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_MESSAGE_FAILED, error: error });
        });
};

export const updateMerchantMessage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_MESSAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_MESSAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/message_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_MESSAGE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_MESSAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_MESSAGE_FAILED, error: error });
        });
};

export const deleteMerchantMessage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_MESSAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_MESSAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/message_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_MESSAGE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_MESSAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_MESSAGE_FAILED, error: error });
        });
};

export const getMerchantLicensePage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/license_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_PAGE_FAILED, error: error });
        });
};

export const getMerchantLicenseDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/license_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                result.data.data.licenseExpireDate = result.data.data.licenseExpireDate === null || result.data.data.licenseExpireDate === "" ? "" : moment(result.data.data.licenseExpireDate);
                dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_LICENSE_DETAIL_FAILED, error: error });
        });
};

export const createMerchantLicense = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_LICENSE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_LICENSE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/license_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_LICENSE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_LICENSE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_LICENSE_FAILED, error: error });
        });
};

export const updateMerchantLicense = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_LICENSE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_LICENSE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/license_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_LICENSE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_LICENSE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_LICENSE_FAILED, error: error });
        });
};

export const deleteMerchantLicense = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_LICENSE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_LICENSE_BREAK });
    }
    await axios
        .post(
            Config.Server + "setting/license_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_LICENSE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_LICENSE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_LICENSE_FAILED, error: error });
        });
};

export const uploadMerchantLicense = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPLOAD_MERCHANT_LICENSE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPLOAD_MERCHANT_LICENSE_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "setting/license_image", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_LICENSE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_LICENSE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPLOAD_MERCHANT_LICENSE_FAILED, error: error });
        });
};

export const getMerchantFilePage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_FILE_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_FILE_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_FILE_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_FILE_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_FILE_PAGE_FAILED, error: error });
        });
};

export const getMerchantFileDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_MERCHANT_FILE_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_MERCHANT_FILE_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_MERCHANT_FILE_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_MERCHANT_FILE_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_MERCHANT_FILE_DETAIL_FAILED, error: error });
        });
};

export const createMerchantFile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_MERCHANT_FILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_MERCHANT_FILE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_MERCHANT_FILE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_MERCHANT_FILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_MERCHANT_FILE_FAILED, error: error });
        });
};

export const updateMerchantFile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_MERCHANT_FILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_MERCHANT_FILE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_FILE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_MERCHANT_FILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_MERCHANT_FILE_FAILED, error: error });
        });
};

export const deleteMerchantFile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_MERCHANT_FILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_MERCHANT_FILE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_MERCHANT_FILE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_MERCHANT_FILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_MERCHANT_FILE_FAILED, error: error });
        });
};

export const uploadMerchantFile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPLOAD_MERCHANT_FILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPLOAD_MERCHANT_FILE_BREAK });
    }
    routeParams.append("userCookie", userCookie);
    await axios
        .post(Config.Server + "agent/file_upload", routeParams, {
            headers: {
                Ropin: Config.RopinKey,
            },
        })
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_FILE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPLOAD_MERCHANT_FILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPLOAD_MERCHANT_FILE_FAILED, error: error });
        });
};

export const downloadMerchantFile = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DOWNLOAD_MERCHANT_FILE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DOWNLOAD_MERCHANT_FILE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/file_download",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DOWNLOAD_MERCHANT_FILE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DOWNLOAD_MERCHANT_FILE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DOWNLOAD_MERCHANT_FILE_FAILED, error: error });
        });
};

export const getAgentProductRulePage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_PAGE_FAILED, error: error });
        });
};

export const getAgentProductRuleDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                result.data.data.ruleDateRange = [moment(result.data.data.ruleDateRange[0] === "1970-01-01" ? new Date() : result.data.data.ruleDateRange[0]), moment(result.data.data.ruleDateRange[1] === "1970-01-01" ? new Date() : result.data.data.ruleDateRange[1])];
                dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_PRODUCT_RULE_DETAIL_FAILED, error: error });
        });
};

export const batchCreateAgentProductRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_batch_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.BATCH_CREATE_AGENT_PRODUCT_RULE_FAILED, error: error });
        });
};

export const createAgentProductRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_AGENT_PRODUCT_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_AGENT_PRODUCT_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_AGENT_PRODUCT_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_AGENT_PRODUCT_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_AGENT_PRODUCT_RULE_FAILED, error: error });
        });
};

export const updateAgentProductRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_AGENT_PRODUCT_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_AGENT_PRODUCT_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_AGENT_PRODUCT_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_AGENT_PRODUCT_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_AGENT_PRODUCT_RULE_FAILED, error: error });
        });
};

export const deleteAgentProductRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_AGENT_PRODUCT_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_AGENT_PRODUCT_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/product_rule_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_AGENT_PRODUCT_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_AGENT_PRODUCT_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_AGENT_PRODUCT_RULE_FAILED, error: error });
        });
};

export const getAgentAddonRulePage = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_PAGE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_PAGE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/addon_rule_result",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_PAGE_SUCCESS, data: result.data.data, totalRecords: result.data.totalRecords, currentPage: result.data.currentPage, totalPages: result.data.totalPages });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_PAGE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_PAGE_FAILED, error: error });
        });
};

export const getAgentAddonRuleDetail = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_DETAIL_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_DETAIL_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/addon_rule_detail",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                result.data.data.ruleDateRange = [moment(result.data.data.ruleDateRange[0] === "1970-01-01" ? new Date() : result.data.data.ruleDateRange[0]), moment(result.data.data.ruleDateRange[1] === "1970-01-01" ? new Date() : result.data.data.ruleDateRange[1])];
                dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_DETAIL_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_DETAIL_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.GET_AGENT_ADDON_RULE_DETAIL_FAILED, error: error });
        });
};

export const createAgentAddonRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.CREATE_AGENT_ADDON_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.CREATE_AGENT_ADDON_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/Addon_rule_add",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.CREATE_AGENT_ADDON_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.CREATE_AGENT_ADDON_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.CREATE_AGENT_ADDON_RULE_FAILED, error: error });
        });
};

export const updateAgentAddonRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.UPDATE_AGENT_ADDON_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.UPDATE_AGENT_ADDON_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/addon_rule_edit",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.UPDATE_AGENT_ADDON_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.UPDATE_AGENT_ADDON_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.UPDATE_AGENT_ADDON_RULE_FAILED, error: error });
        });
};

export const deleteAgentAddonRule = (routeParams) => async (dispatch) => {
    dispatch({ type: merchantActions.DELETE_AGENT_ADDON_RULE_START });
    let userCookie = localStorage.getItem("userCookie");
    if (!userCookie) {
        return dispatch({ type: merchantActions.DELETE_AGENT_ADDON_RULE_BREAK });
    }
    await axios
        .post(
            Config.Server + "agent/addon_rule_delete",
            {
                ...routeParams,
                userCookie: userCookie,
            },
            {
                headers: {
                    Ropin: Config.RopinKey,
                },
            }
        )
        .then((result) => {
            if (result.status === 200 && result.data.info.success) {
                dispatch({ type: merchantActions.DELETE_AGENT_ADDON_RULE_SUCCESS, data: result.data.data });
            } else {
                dispatch({ type: merchantActions.DELETE_AGENT_ADDON_RULE_FAILED, error: result.data.info.message });
            }
        })
        .catch((error) => {
            dispatch({ type: merchantActions.DELETE_AGENT_ADDON_RULE_FAILED, error: error });
        });
};
