import { orderActions } from "../actions/actionTypes";
import { orderInitState, orderDetailPageInitState, orderPageInitState, orderFeeInitState, orderFeeDetailPageInitState, orderLogInitState } from "../model";

export const orderDetail = (state = orderInitState, action) => {
    switch (action.type) {
        case orderActions.GET_ORDER_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case orderActions.GET_ORDER_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.GET_ORDER_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.GET_ORDER_DETAIL_PAGE_START:
            return {
                ...orderDetailPageInitState,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case orderActions.GET_ORDER_DETAIL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_DETAIL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.GET_ORDER_DETAIL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderPage = (state = orderPageInitState, action) => {
    switch (action.type) {
        case orderActions.GET_ORDER_PAGE_START:
            return {
                ...orderPageInitState,
                isLoading: true,
                error: action.error,
            };
        case orderActions.GET_ORDER_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case orderActions.GET_ORDER_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderAction = (state = orderInitState, action) => {
    switch (action.type) {
        case orderActions.EXPORT_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case orderActions.EXPORT_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.EXPORT_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.EXPORT_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.CREATE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CREATE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CREATE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.UPDATE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.UPDATE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.UPDATE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.RECEIVE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.RECEIVE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.RECEIVE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.RECEIVE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.CONFIRM_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CONFIRM_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CONFIRM_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CONFIRM_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.CANCEL_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CANCEL_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CANCEL_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.COMPLETE_ORDER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.COMPLETE_ORDER_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.COMPLETE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.COMPLETE_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.UPDATE_ORDER_COMBINE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.UPDATE_ORDER_COMBINE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.UPDATE_ORDER_COMBINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.UPDATE_ORDER_COMBINE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.UPDATE_ORDER_LOCATION_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.UPDATE_ORDER_LOCATION_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.UPDATE_ORDER_LOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.UPDATE_ORDER_LOCATION_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.UPDATE_ORDER_CONFIRM_NOTE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.UPDATE_ORDER_CONFIRM_NOTE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.UPDATE_ORDER_CONFIRM_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.UPDATE_ORDER_CONFIRM_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.UPDATE_ORDER_OPERATOR_NOTE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.UPDATE_ORDER_OPERATOR_NOTE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.UPDATE_ORDER_OPERATOR_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.UPDATE_ORDER_OPERATOR_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.CHECK_ORDER_SEAT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CHECK_ORDER_SEAT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CHECK_ORDER_SEAT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CHECK_ORDER_SEAT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.SEND_ORDER_EMAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.SEND_ORDER_EMAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.SEND_ORDER_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.SEND_ORDER_EMAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderLog = (state = orderLogInitState, action) => {
    switch (action.type) {
        case orderActions.GET_ORDER_LOG_START:
            return {
                ...orderLogInitState,
                isLoading: true,
                error: action.error,
            };
        case orderActions.GET_ORDER_LOG_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case orderActions.GET_ORDER_LOG_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderFeeDetail = (state = orderFeeInitState, action) => {
    switch (action.type) {
        case orderActions.GET_ORDER_FEE_DETAIL_START:
            return {
                ...orderFeeInitState,
                isLoading: true,
                error: action.error,
            };
        case orderActions.GET_ORDER_FEE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_FEE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case orderActions.GET_ORDER_FEE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case orderActions.GET_ORDER_FEE_DETAIL_PAGE_START:
            return {
                ...orderFeeDetailPageInitState,
                isLoading: true,
                error: action.error,
            };
        case orderActions.GET_ORDER_FEE_DETAIL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.GET_ORDER_FEE_DETAIL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case orderActions.GET_ORDER_FEE_DETAIL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderFeeAction = (state = orderFeeInitState, action) => {
    switch (action.type) {
        case orderActions.CREATE_ORDER_FEE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CREATE_ORDER_FEE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CREATE_ORDER_FEE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CREATE_ORDER_FEE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case orderActions.CANCEL_ORDER_FEE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case orderActions.CANCEL_ORDER_FEE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case orderActions.CANCEL_ORDER_FEE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case orderActions.CANCEL_ORDER_FEE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
