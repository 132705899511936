import { financeActions } from "../actions/actionTypes";
import { financeInitState, invoiceInitState, invoicePageInitState, billInitState, billPageInitState, merchantAccountPageInitState } from "../model";

export const financeAction = (state = financeInitState, action) => {
    switch (action.type) {
        case financeActions.UPDATE_PAYMENT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case financeActions.UPDATE_PAYMENT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.UPDATE_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const channelAccountAll = (state = merchantAccountPageInitState, action) => {
    switch (action.type) {
        case financeActions.GET_ALL_CHANNEL_ACCOUNT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case financeActions.GET_ALL_CHANNEL_ACCOUNT_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.GET_ALL_CHANNEL_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.GET_ALL_CHANNEL_ACCOUNT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const invoiceDetail = (state = invoiceInitState, action) => {
    switch (action.type) {
        case financeActions.GET_INVOICE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case financeActions.GET_INVOICE_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.GET_INVOICE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.GET_INVOICE_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const invoicePage = (state = invoicePageInitState, action) => {
    switch (action.type) {
        case financeActions.GET_INVOICE_PAGE_START:
            return {
                ...invoicePageInitState,
                isLoading: true,
                error: action.error,
            };
        case financeActions.GET_INVOICE_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.GET_INVOICE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case financeActions.GET_INVOICE_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const invoiceAction = (state = invoiceInitState, action) => {
    switch (action.type) {
        case financeActions.BILL_INVOICE_START:
            return {
                ...invoiceInitState,
                isLoading: true,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.BILL_INVOICE_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.BILL_INVOICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.BILL_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const billDetail = (state = billInitState, action) => {
    switch (action.type) {
        case financeActions.GET_BILL_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                error: "",
            };
        case financeActions.GET_BILL_DETAIL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.GET_BILL_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.GET_BILL_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const billPage = (state = billPageInitState, action) => {
    switch (action.type) {
        case financeActions.GET_BILL_PAGE_START:
            return {
                ...billPageInitState,
                isLoading: true,
                error: action.error,
            };
        case financeActions.GET_BILL_PAGE_BREAK:
            return {
                ...state,
                isLoading: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.GET_BILL_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
            };
        case financeActions.GET_BILL_PAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const billAction = (state = billInitState, action) => {
    switch (action.type) {
        case financeActions.CONFIRM_BILL_START:
            return {
                ...billInitState,
                isLoading: true,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.CONFIRM_BILL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.CONFIRM_BILL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.CONFIRM_BILL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.CANCEL_BILL_START:
            return {
                ...billInitState,
                isLoading: true,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.CANCEL_BILL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.CANCEL_BILL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.CANCEL_BILL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.SEND_BILL_START:
            return {
                ...billInitState,
                isLoading: true,
                isSuccess: false,
                error: action.error,
            };
        case financeActions.SEND_BILL_BREAK:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "PERMISSION DENIED",
            };
        case financeActions.SEND_BILL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                data: action.data,
            };
        case financeActions.SEND_BILL_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};
